import React from "react";
import "./AboutUs.css";
import arrowIcon from "../Assets/Homepage/arrowIcon.svg";
import aboutUsPageBackgroundImage from "../Assets/AboutUs/aboutUsPageBackgroundImage.webp";
import aboutUsPageBackgroundMobileImage from "../Assets/AboutUs/aboutUsPageBackgroundMobileImage.webp";
import ourStoryImage from "../Assets/AboutUs/ourStoryImage.webp";
import ourPromiseImage from "../Assets/AboutUs/ourPromiseImage.webp";
import ourPromiseMobileImage from "../Assets/AboutUs/ourPromiseMobileImage.webp";
import ourVisionImage from "../Assets/AboutUs/ourVisionImage.webp";
import ourMissionImage from "../Assets/AboutUs/ourMissionImage.webp";
import enquiryBgImage from "../Assets/AboutUs/enquiryBgImage.webp";
import enquiryBgMobileImage from "../Assets/AboutUs/enquiryBgMobileImage.webp";
import ContactUsForm from "./ContactUsForm";
import { useNavigate } from "react-router-dom";
import SEO from "./SEO";
const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <SEO
        title="About Us"
        description=
          "We at CREXIN have passionate professionals like us, who can make our customers' dreams come true with cost-effective, sustainable, and scalable solutions."
      />
      <div className="about-page-container">
        <div className="about-page-section1-container">
          <div className="about-page-section1-background-image">
            {window.innerWidth <= 430 ? (
              <img src={aboutUsPageBackgroundMobileImage} alt="About Us" />
            ) : (
              <img src={aboutUsPageBackgroundImage} alt="About Us" />
            )}
          </div>
          <div className="about-page-section1-backgorund-layer">
            <div className="about-page-section1-description">
              <h2>Custom Software Solutions for Every Need</h2>
              <p>
                Our unwavering commitment to innovation and customer
                satisfaction drives us to deliver transformative services such
                as digital transformation, custom software solutions, and
                enterprise application development. 
              </p>
              <p>By integrating cutting-edge
                technologies like cloud computing solutions, business
                optimization tools, and interactive web design, we ensure
                exceptional results that adapt to the dynamic demands of the
                digital landscape, enabling meaningful and sustained growth.</p>
              <button
                className="about-page-contact-us-container-button"
                onClick={() => {
                  navigate("/enquiry-with-us");
                }}
              >
                <p>Contact us</p>
                <img src={arrowIcon} alt="lets chat" />
              </button>
            </div>
          </div>
        </div>
        <div className="about-page-section2-container">
          <div className="about-page-section2-description">
            <h3>
              Our <span>Story</span>
            </h3>
            <p>
              IT Professionals with more than 15 Years of experience and nearly
              two years of background work, with lots of passion, started
              looking for Web Development Service providers. After weeks of
              research found a Company whom we trusted and had high hopes that
              they could get our product online. Days passed on, weeks passed
              on, months passed on, and even after a year, all our hopes, time,
              and efforts went in vain as they couldn't deliver anything they
              promised. We were left with nothing but a huge disappointment.
            </p>
            <p>
              With such a bitter experience, we started thinking, as IT
              professionals with over a decade of Industry Experience working
              with top MNCs around the globe can do much better in this space.
              None of the startups like us should undergo what we went through
              and fail to fulfill their dreams. With all the lessons learned, we
              are here now providing the services/solutions to fulfill the
              dreams of Entrepreneurs, startups, and businesses who want to
              bring their products online.
            </p>
          </div>
          <div className="about-page-section2-image">
            <img src={ourStoryImage} alt="our story" />
          </div>
        </div>
        <div className="about-page-section3-container">
          {window.innerWidth <= 430 ? (
            <img src={ourPromiseMobileImage} alt="our promise" />
          ) : (
            <img src={ourPromiseImage} alt="our promise" />
          )}

          <div className="about-page-section3-description">
            <div className="about-page-section3-description-content">
              <h2>
                Our <span>Promise</span>
              </h2>
              <p>
                At CREXIN, our team of passionate professionals are dedicated to
                turning customer vision into reality through cost-effective,
                sustainable, and scalable solutions. With expertise in custom
                software solutions, enterprise application development, and user
                experience optimization. 
              </p>
              <p>We prioritize understanding our
                clients' needs and delivering quality solutions on time with the
                highest level of accountability. We go beyond conventional
                approaches, creating innovative, future-proof products designed
                for consistency and adaptability to meet evolving business
                demands.</p>
            </div>
          </div>
        </div>
        <div className="about-page-section4-container">
          <div className="about-page-section4-image">
            <img src={ourVisionImage} alt="our vision" />
          </div>
          <div className="about-page-section4-description">
            <h3>
              Our <span>Vision</span>
            </h3>
            <p>
              At the core of our philosophy is the belief that collaboration and
              transparency are key to building long-term partnerships. We work
              closely with our clients, understanding their unique goals and
              challenges, to develop solutions that meet their specific needs.
              We value open communication, integrity, and a proactive approach
              in every project we undertake.
            </p>
          </div>
        </div>
        <div className="about-page-section5-container">
          <div className="about-page-section5-description">
            <h3>
              Our <span>Mission</span>
            </h3>
            <p>
              Our mission is to empower businesses by providing innovative
              solutions that drive growth, efficiency, and success. We are
              committed to leveraging the latest technologies, industry
              expertise, and a client-centric approach to deliver exceptional
              results.
            </p>
          </div>
          <div className="about-page-section5-image">
            <img src={ourMissionImage} alt="our mission" />
          </div>
        </div>
        <div className="about-page-section6-container">
          <div className="about-page-section6-image">
            {window.innerWidth <= 430 ? (
              <img
                src={enquiryBgMobileImage}
                alt="Custom software solutions designed to streamline workflows and operations."
              />
            ) : (
              <img
                src={enquiryBgImage}
                alt="Custom software solutions designed to streamline workflows and operations."
              />
            )}
          </div>
          <div className="about-page-section6-description">
            <div className="contact-form-adjustment">
              <ContactUsForm />
            </div>
            <div className="about-page-section6-location-div">
              <h3>Reach out to us</h3>
              <div className="about-page-section6-location-image">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3628406248254!2d78.38126211511396!3d17.442339188045636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb932a49b02403%3A0x6fd3cffbca4f12da!2screxin%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1660037816884!5m2!1sen!2sin"
                  title="CREXIN location"
                  allowtransparency="true"
                  style={{ border: 0 }}
                  allowFullScreen="yess"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="about-page-section6-location-description">
                <p>mail to us : info@crexin.com</p>
                <p>contact us : +91-9059891091</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
