import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MobileApplicationDev.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import madSection1Image from "../../Assets/ServicesPage/madSection1Image.webp";
import madSection2Image from "../../Assets/ServicesPage/madSection2Image.webp";
import madSection2MobileImage from "../../Assets/ServicesPage/madSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";
const MobileApplicationDev = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
    <React.Fragment>
    <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="mob-app-dev-container">
        <div className="mob-app-dev-section1-container">
          <div className="mob-app-dev-section1-content">
            <h3>Mobile Application Development</h3>
            <p>
              Mobile application development involves designing and building
              secure mobile app solutions for devices like smartphones and
              tablets. This process includes crafting intuitive user interfaces,
              integrating hardware-specific features, and ensuring responsive
              performance across platforms such as Android and iOS.
            </p>
            <p>
              By leveraging
              cross-platform UI design and cloud-based application development, we
              ensure seamless compatibility and functionality for diverse user
              needs.
            </p>
            <button
              className="wad-page-contact-us-container-button-2"
              onClick={() => {
                navigate("/enquiry-with-us");
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="mob-app-dev-section1-image">
            <img
              src={madSection1Image}
              alt="Secure mobile app development services to protect user data."
            />
          </div>
        </div>
        <div className="mob-app-dev-section2-container">
          <div className="mob-app-dev-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={madSection2MobileImage}
                alt="Secure mobile app development services to protect user data."
              />
            ) : (
              <img
                src={madSection2Image}
                alt="Secure mobile app development services to protect user data."
              />
            )}
          </div>
          <div className="mob-app-dev-section2-content">
            <div className="mob-app-dev-section2-content-left-div">
              <h3>When to consider our Mobile Application Development Service</h3>
              <p>
                We deliver powerful software solutions designed for organizations
                of all sizes, ensuring swift execution with an emphasis on
                quality, maximizing ROI, and meeting the needs of your end users.
              </p>
              <p>
                Achieve your business objectives with our custom software
                solutions, tailored specifically to your requirements. Whether you
                need enterprise application development, secure cloud services, or
                data transformation services, our expertise ensures optimal
                outcomes. Here’s when you should consider our software development
                services: when scalability, reliability, and innovation are your
                top priorities.
              </p>
            </div>
            <div className="mob-app-dev-section2-content-right-div">
              <div className="mob-app-dev-section2-content-right-div-grid">
                <h4>Launching a New Mobile Application</h4>
                <p>
                  If you're launching something new, such as your own app, our
                  software development solutions may give your idea a personal
                  touch, assuring a memorable launch.
                </p>
              </div>
              <div className="mob-app-dev-section2-content-right-div-grid">
                <h4>Revamping an Existing Mobile Application</h4>
                <p>
                  Refresh your current app with advanced features and
                  functionalities. Our custom software development services
                  revitalize your app, ensuring modernization and increased user
                  engagement.
                </p>
              </div>
              <div className="mob-app-dev-section2-content-right-div-grid">
                <h4>Adopting Cross-Platform Development</h4>
                <p>
                  If you're ready to expand the reach of your software, choose
                  cross-platform development with us. We help you broaden the
                  reach of your app.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mob-app-dev-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="mob-app-dev-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${category.id === activeCategory.id ? "active-tech-category" : ""
                  }`}
                onMouseEnter={() => handleMouseEnter(category)}
              //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="mob-app-dev-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="mob-app-dev-section4-container">
          <div className="mob-app-dev-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Secure mobile app development services to protect user data."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Secure mobile app development services to protect user data."
              />
            )}
          </div>
          <div className="mob-app-dev-section4-content-layer">
            <div className="mob-app-dev-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="mob-app-dev-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileApplicationDev;
