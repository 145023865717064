import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GraphicDesignServices.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import gdsSection1Image from "../../Assets/ServicesPage/gdsSection1Image.webp";
import gdsSection2Image from "../../Assets/ServicesPage/gdsSection2Image.webp";
import gdsSection2MobileImage from "../../Assets/ServicesPage/gdsSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";

import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";
const GraphicDesignServices = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
   <React.Fragment>
     <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
    <div className="graph-des-ser-container">
      <div className="graph-des-ser-section1-container">
        <div className="graph-des-ser-section1-content">
          <h3>Graphic Design Services</h3>
          <p>
            Graphic design is not just about creating visually appealing
            content; it’s a strategic tool that can enhance your brand,
            effectively communicate your message, and create memorable user
            experiences. Whether you need a new logo, marketing materials,
            digital content, or custom illustrations, professional graphic
            design services can help you achieve your business goals with impact
            and style. 
          </p>
          <p>Through visual design and branding, we create designs
            that resonate with your audience, ensuring consistency and
            engagement across all touchpoints, ultimately supporting your growth
            in the digital marketplace.</p>
          <button
            className="wad-page-contact-us-container-button-2"
            onClick={() => {
              navigate("/enquiry-with-us");
            }}
          >
            <p>Contact us</p>
            <img src={arrowIcon} alt="lets chat" />
          </button>
        </div>
        <div className="graph-des-ser-section1-image">
          <img
            src={gdsSection1Image}
            alt="Professional custom graphics design services for impactful visuals."
          />
        </div>
      </div>
      <div className="graph-des-ser-section2-container">
        <div className="graph-des-ser-section2-image">
          {window.innerWidth <= 430 ? (
            <img
              src={gdsSection2MobileImage}
              alt="Professional custom graphics design services for impactful visuals."
            />
          ) : (
            <img
              src={gdsSection2Image}
              alt="Professional custom graphics design services for impactful visuals."
            />
          )}
        </div>
        <div className="graph-des-ser-section2-content">
          <div className="graph-des-ser-section2-content-left-div">
            <h3>When to consider our Graphic Design Service</h3>
            <p>
              Graphic design is a powerful tool for visually conveying your
              brand's message, values, and identity. Whether through logos,
              marketing materials, packaging, or digital content, it plays a
              vital role in making a lasting impact on your audience.
            </p>
            <p>
              High-quality graphic design services ensure that every visual
              element of your brand is not only aesthetically pleasing but also
              strategically aligned with your business goals. By focusing on
              visual design and branding, we create cohesive and engaging
              designs that enhance your digital presence and foster a strong
              connection with your target audience, driving long-term business
              success.
            </p>
            {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
          </div>
          <div className="graph-des-ser-section2-content-right-div">
            <div className="graph-des-ser-section2-content-right-div-grid">
              <h4>Launching a New Product</h4>
              <p>
                If you’re launching a new brand or product, professional graphic
                design services can help you create a strong visual identity and
                marketing materials that stand out in the market.
              </p>
            </div>
            <div className="graph-des-ser-section2-content-right-div-grid">
              <h4>Expanding Marketing Efforts</h4>
              <p>
                If you’re looking to expand your marketing efforts into new
                channels or platforms, custom-designed graphics can help you
                make a more significant impact.
              </p>
            </div>
            <div className="graph-des-ser-section2-content-right-div-grid">
              <h4>Enhancing Customer Engagement</h4>
              <p>
                If you want to boost customer engagement on social media,
                through email campaigns, or on your website, compelling visual
                content is key.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="graph-des-ser-section3-container">
        <h3>
          <span>Technologies</span> used by us{" "}
        </h3>
        <p>
          Our developers use a proven combination of the latest and existing
          trusted technologies, with the following technology stack as the
          foundation:
        </p>
        <div className="graph-des-ser-tecnhology-div">
          {technologiesData.map((category, index) => (
            <div
              key={category.id}
              className={`tech-category ${
                category.id === activeCategory.id ? "active-tech-category" : ""
              }`}
              onMouseEnter={() => handleMouseEnter(category)}
              //   onMouseLeave={handleMouseLeave}
            >
              <p>{category.name}</p>
            </div>
          ))}
        </div>
        <div className="graph-des-ser-technology-techskills">
          <div className="skills-list">
            {activeCategory.technicalSkills &&
              activeCategory.technicalSkills.map((skill, index) => (
                <div key={skill.id} className="tech-skill">
                  <p>{skill.techName}</p>
                  {skill.icon && (
                    <img src={skill.icon} alt={`${skill.techName}`} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="graph-des-ser-section4-container">
        <div className="graph-des-ser-section4-image">
          {window.innerWidth <= 430 ? (
            <img
              src={wadSection3MobileImage}
              alt="Professional custom graphics design services for impactful visuals."
            />
          ) : (
            <img
              src={wadSection3Image}
              alt="Professional custom graphics design services for impactful visuals."
            />
          )}
        </div>
        <div className="graph-des-ser-section4-content-layer">
          <div className="graph-des-ser-section4-content">
            <div className="contact-us-form-container">
              <ContactUsForm />
            </div>
            <div className="graph-des-ser-section4-right-div">
              <h3>Let's Talk About Your Project</h3>
              <p>
                We are eager to discuss and understand your custom software
                development needs. Whether you have a detailed plan or just a
                concept, we're ready to transform it into a practical and
                tailored solution.
              </p>
              <p>
                Our services prioritize simplicity, effectiveness, and
                collaboration. Ready to move forward? Let's talk about your
                project—fill out the form and click "Submit" to get started.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
   </React.Fragment>
  );
};

export default GraphicDesignServices;
