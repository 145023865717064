import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import "./SdlcOverview.css";
import SDLCOverview from "../../Assets/Blogs/SDLCOverview.webp";
import SEO from "../SEO";
const SdlcOverview = (props) => {
  const navigate = useNavigate();
  const [activeSubheading, setActiveSubheading] = useState(null);
  const postContentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const subheadings = document.querySelectorAll(".post-content h2"); // Adjust for your specific heading level (h2, h3, etc.)

      subheadings.forEach((subheading) => {
        const section = document.getElementById(subheading.id);
        const rect = section.getBoundingClientRect();

        // Adjust the threshold as needed; 0 means when it's at the top of the viewport
        if (rect.top <= 50 && rect.bottom >= 0) {
          setActiveSubheading(subheading.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
    <SEO
        title="Blogs"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="blog2-main-container">
        <div className="blog2-section1-container">
          <div className="blog2-section1-image-container">
            <img src={SDLCOverview} alt="SDLC Overview" />
          </div>
          <h1>Software Development Life Cycle (SDLC) Overview</h1>
          <p>
            In the ever-evolving world of software development, ensuring a
            structured approach to building, testing, and deploying software is
            essential. The Software Development Life Cycle (SDLC) provides a
            clear, systematic process that guides development teams from project
            initiation to completion. Understanding the SDLC not only improves
            efficiency but also ensures quality and consistency across projects.
          </p>
        </div>
        <div className="blog2-section2-container">
          <div className="blog2-left-sidebar">
            <ul>
              <li
                className={activeSubheading === "subheading1" ? "active" : ""}
              >
                <a href="#subheading1">What is SDLC?</a>
              </li>
              <li
                className={activeSubheading === "subheading2" ? "active" : ""}
              >
                <a href="#subheading2">Phases of SDLC</a>
              </li>
              <li
                className={activeSubheading === "subheading3" ? "active" : ""}
              >
                <a href="#subheading3">SDLC Models and Methodologies</a>
              </li>
              <li
                className={activeSubheading === "subheading4" ? "active" : ""}
              >
                <a href="#subheading4">Conclusion</a>
              </li>
            </ul>
          </div>
          <div className="blog2-section2-description-content">
            <div className="blog2-section2-text1-container post-content" ref={postContentRef}>
              <h2 id="subheading1">What is SDLC?</h2>
              <p>
                The Software Development Life Cycle (SDLC) is a framework that
                defines the steps involved in developing software, from initial
                concept to final deployment and maintenance. It aims to produce
                high-quality software that meets or exceeds customer
                expectations while adhering to deadlines and budget constraints.
                SDLC outlines a structured process, minimizing risks and
                ensuring that development efforts align with business goals.
              </p>
            </div>
            <div className="blog2-section2-text2-container post-content" >
              <h2 id="subheading2">Phases of SDLC</h2>
              <p>SDLC typically consists of the following phases:</p>
              <ol type="1">
                <li>
                  <h3>Requirement Analysis</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Gather and analyze user
                        requirements.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Stakeholder interviews,
                        requirement documentation, feasibility study.
                      </li>
                      <li>
                        <strong>Outcome:</strong> A clear understanding of the
                        project scope and user needs.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3> Planning</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Define project goals,
                        timelines, and resources.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Project scheduling,
                        budget estimation, risk assessment.
                      </li>
                      <li>
                        <strong>Outcome:</strong> A detailed project plan
                        outlining deliverables and milestones.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Design</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Create a blueprint for the
                        software solution.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> System architecture
                        design, UI/UX prototyping, database design.
                      </li>
                      <li>
                        <strong>Outcome:</strong> Detailed design specifications
                        that guide the development process.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3> Implementation (Coding)</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Develop the software based
                        on design specifications.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Writing code,
                        integrating modules, version control management.
                      </li>
                      <li>
                        <strong>Outcome:</strong> Functioning software modules
                        ready for testing.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Testing</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Identify and fix defects in
                        the software.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Unit testing,
                        integration testing, system testing, user acceptance
                        testing (UAT).
                      </li>
                      <li>
                        <strong>Outcome:</strong> A stable and reliable software
                        product.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Deployment</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Release the software to
                        users.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Deployment planning,
                        environment setup, go-live execution.
                      </li>
                      <li>
                        <strong>Outcome:</strong> Fully operational software
                        available to end-users.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Maintenance</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Objective:</strong> Ensure the software
                        continues to function as intended.
                      </li>
                      <li>
                        <strong>Key Activities:</strong> Bug fixes, performance
                        monitoring, updates, user support.
                      </li>
                      <li>
                        <strong>Outcome:</strong> Sustained software performance
                        and user satisfaction.
                      </li>
                    </ul>
                  </p>
                </li>
              </ol>
            </div>
            <div className="blog2-section2-text3-container post-content">
              <h2 id="subheading3">SDLC Models and Methodologies</h2>
              <p>
                Different projects may require different SDLC approaches. Here
                are the most common SDLC models:
              </p>
              <ol>
                <li>
                  <h3>Waterfall Model</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong> A linear, sequential
                        approach where each phase must be completed before the
                        next begins.
                      </li>
                      <li>
                        <strong>Best For:</strong> Small, well-defined projects
                        with clear requirements.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Agile Model</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong> An iterative, incremental
                        approach that emphasizes collaboration and flexibility.
                      </li>
                      <li>
                        <strong>Best For:</strong> Complex, evolving projects
                        where requirements may change.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Iterative Model</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong> Involves building a basic
                        version of the software, then enhancing it through
                        multiple iterations.
                      </li>
                      <li>
                        <strong>Best For:</strong> Large projects that can be
                        broken down into smaller components.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>V-Model (Verification and Validation)</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong>Similar to the Waterfall
                        Model but emphasizes testing at each development stage.
                      </li>
                      <li>
                        <strong>Best For:</strong> Projects requiring rigorous
                        validation, such as in healthcare or aviation.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>Spiral Model</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong> Combines iterative
                        development with risk assessment.
                      </li>
                      <li>
                        <strong>Best For:</strong> High-risk projects with
                        complex requirements.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  <h3>DevOps Model</h3>
                  <p>
                    <ul>
                      <li>
                        <strong>Description:</strong>Focuses on collaboration
                        between development and operations teams, automating
                        processes for continuous integration and delivery
                        (CI/CD).
                      </li>
                      <li>
                        <strong>Best For:</strong> Projects requiring rapid
                        deployment and continuous updates.
                      </li>
                    </ul>
                  </p>
                </li>
              </ol>
            </div>
            <div className="blog2-section2-text4-container post-content">
              <h2 id="subheading4">Conclusion</h2>
              <p>
                Understanding the Software Development Life Cycle (SDLC) is
                crucial for delivering high-quality software solutions. By
                following a structured approach through well-defined phases,
                teams can ensure consistency, reduce risks, and meet user
                expectations effectively.
              </p>
              <p>
                Choosing the right SDLC model depends on the project's scope,
                complexity, and requirements, making it essential to tailor your
                approach for optimal results. Whether you adopt a Waterfall,
                Agile, or DevOps model, applying SDLC principles will enhance
                your project's success and sustainability in the competitive
                software landscape.
              </p>
            </div>
          </div>
          <div className="blog2-right-sidebar">
            <div className="blog2-right-sidebar-get-in-touch-with-us-container-description">
              <h4>Get in touch with us</h4>
              <p>For more details and enquiry please click the below button.</p>
            </div>
            <button
              className="blog2-right-sidebar-get-in-touch-with-us-container-button"
              onClick={() => {
                navigate("/enquiry-with-us");
                props.scrollToTop();
              }}
            >
              <p>Let's Chat</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
            <div className="blog2-right-sidebar-related-topics-container">
              <p>Also checkout these latest blogs</p>
              <div className="blog2-right-sidebar-related-topics-routes">
                <p
                  onClick={() => {
                    navigate(
                      "/blogs/importance-of-mobile-first-design-in-modern-projects"
                    );
                    props.scrollToTop();
                  }}
                >
                  The Importance of Mobile-First Design in Modern Projects
                </p>

                <p
                  onClick={() => {
                    navigate(
                      "/blogs/poc-prototype-mvp-choosing-the-right-path"
                    );
                    props.scrollToTop();
                  }}
                >
                  PoC, Prototype, or MVP: Choosing the Right Path
                </p>

                <p
                  onClick={() => {
                    navigate(
                      "/blogs/how-to-control-your-software-development-budget"
                    );
                    props.scrollToTop();
                  }}
                >
                  How to Control Your Software Development Budget: Revision
                  Costs
                </p>

                <p
                  onClick={() => {
                    navigate(
                      "/blogs/static-vs-dynamic-web-app-development-which-is-right-for-your-project"
                    );
                    props.scrollToTop();
                  }}
                >
                  Static vs. Dynamic Web App Development: Which is Right for
                  Your Project?
                </p>

                <p
                  onClick={() => {
                    navigate(
                      "/blogs/how-to-choose-the-right-tech-stack-for-your-website-development"
                    );
                    props.scrollToTop();
                  }}
                >
                  How to Choose the Right Tech Stack for Your Website
                  Development
                </p>

                <p
                  onClick={() => {
                    navigate(
                      "/blogs/cross-platform-vs-native-app-development-which-should-you-choose"
                    );
                    props.scrollToTop();
                  }}
                >
                  Cross-Platform vs. Native App Development: Which Should You
                  Choose?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SdlcOverview;
