import React from "react";
import "./PrivacyPolicy.css";
import SEO from "./SEO";
const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <SEO
        title="Privacy Policy"
        description="Our steadfast dedication to innovation and customer satisfaction fuels our ability to provide transformative services that meet the constantly changing demands of the digital world, delivering exceptional results and driving meaningful business transformations."
      />
      <div className="privacy-policy-main-container">
        <div className="pp-title-container">
          <h3>PRIVACY POLICY</h3>
        </div>
        <div className="last-updated-container">
          <p>Last updated: 1st September 2024</p>
        </div>
        <div className="pp-description-container">
          <h4>General</h4>
          <ol type="a">
            <li>
              This website with the URL of www.CREXIN.com ("website/site") is
              operated by CREXIN TECHNOLOGIES PRIVATE LIMITED("we/our/us"). We’re
              committed to protecting and respecting your privacy. We do collect
              your personal information and process your personal data in
              accordance with the IT Act, 2000-21 and other national and state
              laws which relate the processing of personal data. Please read the
              following carefully to understand our views and practices regarding
              your personal data.
            </li>
            <li>
              We collect your personal information in order to provide and
              continually improve our products and Services.
            </li>
            <li>
              Our privacy policy is subject to change at any time without notice.
              To make sure you are aware of Any changes, please review this policy
              periodically.
            </li>
            <li>
              All partner firms and any third-party working with or for us, and
              who have access to personal information, will be expected to read
              and comply with this policy. No third party may access, or Process
              sensitive personal information held by us without having first
              interests a confidentiality Agreement.
            </li>
          </ol>

          <h4>How we collect the information</h4>
          <ol type="a">
            <li>
              From you directly and through this site: we may collect information
              through the website and mobile applications when you visit. The data
              we collect depends on the context of your interactions with our
              website.
            </li>
            <li>
              Through business interaction: we may collect information through
              business interaction with you or Your employees.
            </li>
            <li>
              From other sources: we may receive information from other sources,
              such as public and or private databases.
            </li>
          </ol>

          <h4>Information we collect</h4>
          <ol type="a">
            <li>
              We collect information primarily to provide better services to all
              of our customers.
            </li>
            <li>
              We collect the following information from you when you use or signup
              our website: Name, Mobile Number, Worksite address, Billing address
              etc.
            </li>
            <li>
              When you use visit our site, some information is automatically
              collected. This may include information such as the operating
              systems (OS) running on your device, internet protocol (IP) address,
              access Times, browser type, and language, and the website you
              visited before our site. We also collect Information about how you
              use our products or services.
            </li>
            <li>
              We automatically collect purchase or content use history, which we
              sometimes aggregate with Similar information from other customers to
              create features such as best seller, top rated, etc.
            </li>
            <li>
              The full uniform resource locators (URL) click stream to, through
              and from our website (including Date and time); cookie number;
              products and/or content you viewed or searched for, page response
              Times; download errors; length of visits to certain pages; page
              interaction information (such as scrolling , clicks and mouse-overs)
            </li>
            <li>
              We automatically collect information using "cookies". Cookies are
              small data files stored on your Hard drive the websites. Among other
              things, cookies help us improve our site, our marketing Activities,
              and your experience. We use cookies to see which areas and features
              are popular and to Count visits to our site
            </li>
            <li>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can choose to set your Browser to remove cookies and to
              reject cookies. If you set your browser to reject cookies, some
              Features will be unavailable. For more information on how to reject
              cookies, see your browser's Instructions on changing your cookie
              settings.
            </li>
            <li>
              By using this website, you are agreeing that we may advertise your
              feedback on the website and Marketing materials. We will retain your
              information as long as we require this to provide you with the goods
              and Services and for such period as mandated by the concerned laws.
            </li>
            <li>
              If you opt to receive marketing correspondence from us, subscribe to
              our mailing list or newsletters Enter into any of our competitions
              or provide us with your details at networking events, we may use
              Your personal data for our legitimate interests in order to provide
              you with details about our goods, Services, business updates and
              events.
            </li>
          </ol>

          <h4>How we use information</h4>
          <ol type="a">
            <li>
              We use the information we collect primarily to provide, maintain ,
              protect and improve our current products and services.
            </li>
            <li>
              We use the information collected through this website as described
              in this policy and we may use your information to:
              <ol type="I">
                <li>
                  Improve our services, site and how we operate our businesses.
                </li>
                <li>
                  Understand and enhance your experience using our site, products
                  and services.
                </li>
                <li>
                  Personalize our products or services and make recommendations.
                </li>
                <li>
                  Provide and deliver products and services you request; process,
                  manage, complete, and account for transactions.
                </li>
                <li>
                  Provide customer support and respond to your requests, comments,
                  and inquiries.
                </li>
                <li>
                  Create and manage the online accounts you manage on our website.
                </li>
                <li>
                  Send you related information, including confirmations, invoices,
                  technical notices, updates.
                </li>
                <li>Security alerts and support and administrative messages.</li>
                <li>
                  Communicate with you about promotions, upcoming events and news
                  about products and Services.
                </li>
                <li>
                  We may process your personal information without your knowledge
                  or consent where required by applicable law or regulation for
                  the purposes of verification of identity or for prevention,
                  detection or investigation, including of cyber incidents,
                  prosecution and punishment of offences:
                </li>
                <li>
                  Protect, investigate and deter against fraudulent, unauthorized
                  or illegal activity.
                </li>
              </ol>
            </li>
          </ol>
          <h4>Data transfer</h4>
          <ol type="a">
            <li>
              Information about our user is an important part of our business and
              we take due care to protect the same. We share your data with your
              consent or to complete any transaction or provide any product or
              service you have requested or authorized. We also share data with
              our affiliates and subsidiaries, with vendors working on our behalf.
            </li>
            <li>
              We may employ other companies and individuals to perform functions
              on our behalf. The functions include fulfilling orders for products
              or services, delivering packages, sending postal mail and e-mail,
              removing repetitive information from customer lists, providing
              marketing assistance, providing search results and links (including
              paid listings and links), processing payments, transmitting content,
              scoring credit risk and providing customer service.
            </li>
            <li>
              This third-party service provides have access to personal
              information needed to perform their functions but may not use it for
              other purposes. Further they must process the personal information
              in accordance with this privacy policy and as permitted by
              applicable data protection laws.
            </li>
            <li>
              We release account and other personal information when we believe is
              appropriate to comply with the law, enforce or apply our conditions
              of use, and other agreements, protect the rights, property or safety
              of us, our users or others. This includes exchanging information
              with other companies and organizations for fraud protection and
              credit risk reduction.
            </li>
          </ol>

          <h4>Cookies</h4>
          <ol type="a">
            <li>
              To optimize our web presence, we use cookies. These are small text
              files stored on your computer's main memory. These cookies are
              deleted after you close the browser. Other cookies remain on your
              computer (long-term cookies) and permit its recognition on your next
              visit. This allows us to improve your access to our site. This helps
              us to learn more about your interests, and provide you with
              essential features and services and for additional purposes,
              including:
              <ol type="I">
                <li>Keeping track of items stored in your shopping basket.</li>
                <li>
                  Conducting research and diagnostics to improve the content,
                  products, and services.
                </li>
                <li>Preventing fraudulent activity</li>
                <li>Improving security.</li>
              </ol>
            </li>
            <li>
              Our cookies allow you to take advantage of some of our essential
              features. For instance, if you block or otherwise reject our
              cookies, you will not be able tr add items to your shopping basket,
              proceed to checkout, or use any products or services that require
              you to sign in. Approved third parties also may set cookies when you
              interact with our services.
            </li>
            <li>
              Third parties include search engines, providers of measurement and
              analytics services, social media networks, and advertising
              companies. Third parties use cookies in the process of delivering
              content, including ads relevant to your Interests, to measure the
              effectiveness of their ads, and to perform services on behalf of us.
            </li>
            <li>
              You can prevent the storage of cookies by choosing a "disable
              cookies" option in your browse settings. But this can limit the
              functionality of our services.
            </li>
          </ol>

          <h4>Data security</h4>
          <ol type="a">
            <li>
              We take due care to protect customer data. Technical measures are in
              place to prevent unauthorized or unlawful access to data and against
              accidental loss or destruction of, or damage to, data. The employees
              who are dealing with the data have been trained to protect the data
              from any Illegal or unauthorized usage.
            </li>
            <li>
              We work to protect the security of your information during
              transmission by using secure sockets Locker (SSL) software, which
              encrypts information you input. SSL allows sensitive information
              such As credit card numbers, UID's and login credentials to be
              transmitted securely.
            </li>
            <li>
              We follow the payment card industry data security standard (PCI DSS)
              when handling branded Credit cards from the major card schemes.
            </li>
            <li>
              We maintain physical, electronic, and procedural safeguards in
              connection with the collection, Storage, and disclosure of personal
              customer information.
            </li>
            <li>
              We take reasonable steps to help protect your personal information
              in an effort to prevent the loss, Misuse, and unauthorized access,
              disclosure alteration and destruction. It is your responsibility to
              protect your usernames and passwords to help prevent anyone from
              accessing or abusing your accounts and services. You should not use
              or reuse the same passwords you use with other accounts as your
              password for our services.
            </li>
            <li>
              It is important for you to protect against unauthorized access to
              your password and your computers, devices, and applications. Be sure
              to sign off when you finish using a shared computer.
            </li>
            <li>
              Information you provide to us is shared on our secure servers. We
              have implemented appropriate physical, technical and organizational
              measures designed to secure your information against accidental loss
              and unauthorized access, use, alteration or disclosure. In addition,
              we limit access to Personal data to those employees, agents,
              contractors, and other third parties that have a legitimate business
              need for such access.
            </li>
            <li>
              Information collected from you will be stored for such period as
              required to complete the transaction entered into with you or such
              period as mandated under the applicable laws.
            </li>
          </ol>

          <h4>Links to third party site/apps</h4>
          <ol type="a">
            <li>
              Our site may, from time to time, contain links to and from other
              websites of third parties. Please note that if you follow a link to
              any of these websites, such websites will apply different terms to
              the collection and privacy of your personal data, and we do not
              accept any responsibility or liability for these policies. When you
              leave our site, we encourage you to read the privacy policy of every
              website you visit.
            </li>
          </ol>

          <h4>Social network plugins</h4>
          <ol type="a">
            <li>
              This website incorporates plugins ALD/or buttons for social
              networks, in order to allow easy sharing On your favourite social
              networks. These plugins are programmed so as not to set any cookies
              when Assessing the page to safeguard the privacy of users. Cookies
              may be set, if you make voluntary use Of the plugin. The collection
              and use of information obtained by means of the plugin are governed
              by The respective privacy policies of the social networks.
            </li>
          </ol>

          <h4>Sharing of personal information</h4>
          <ol type="a">
            <li>
              We do not share your personal data with third parties without your
              prior consent other than: With third parties who work on our behalf
              provided such third parties adhere to the data protection principles
              set out in me it act, 2000 (21 of 2000) and other applicable
              legislation, or enter into a written agreement with us requiring
              that the third party provide at least the same Level of privacy
              protection as is required by such principles:
              <ol type="I">
                <li>
                  To comply with laws or to respond to lawful requests and legal
                  process.
                </li>
                <li>
                  To protect the rights and property of us, our agents, customers,
                  and others including to enforce our agreements, policies and
                  terms of use.
                </li>
                <li>
                  In an emergency, including to protect the personal safety of any
                  person; and for the purpose of a business deal (or negotiation
                  of a business deal) involving the sale or
                </li>
                <li>
                  Transfer of all or a part of our business or assets (business
                  deals may include, for example, any Merger, financing,
                  acquisition, divestiture or bankruptcy transaction or
                  proceeding).
                </li>
              </ol>
            </li>
          </ol>

          <h4>Children</h4>
          <ol type="a">
            <li>
              If you are under 18, or the age of majority in the jurisdiction in
              which you reside, you may only use our website with the consent of
              your parent or legal guardian. In any case, we will not be liable
              for any cause of action that arose due to non-compliance with this
              section.
            </li>
          </ol>

          <h4>Changes to this policy</h4>
          <ol type="a">
            <li>
              We may change this policy from time to time. If we make any changes
              to this policy we will change the last updated" date above. You
              agree that your continued use of our services after such changes
              have been published to our services will constitute your acceptance
              of such revised policy.
            </li>
          </ol>

          <h4>Newsletter</h4>
          <ol type="a">
            <li>
              Following subscription to the newsletter, your e-mail address is
              used for our advertising purposes Until you cancel the newsletter
              again. Cancellation is possible at any time. The following consent
              has been expressly granted by you separately, or possibly in the
              course of an ordering process: (lam Accepting to receive newsletter
              from this website), you may revoke your consent at any time with
              future effect. If you no longer want to receive the newsletter, then
              unsubscribe by clicking on unsubscribe option given in the email
              footer. If you have any concern about privacy or grievances with us,
              please contact us with a thorough Description and we will try to
              resolve the issue for you.
            </li>
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
