const environment ={
    baseUrl : "http://localhost:9091",
};

if(process.env.REACT_APP_ENV === 'development'){
    environment.baseUrl = "http://localhost:9091";
}
if(process.env.REACT_APP_ENV === 'staging'){
    environment.baseUrl = "https://dev.crexin.com"
}

if(process.env.REACT_APP_ENV === 'production'){
    environment.baseUrl = "https://crexin.com"
}
export default environment;