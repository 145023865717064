import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./WebApplicationDev.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import wadSection1Image from "../../Assets/ServicesPage/wadSection1Image.webp";
import wadSection2Image from "../../Assets/ServicesPage/wadSection2Image.webp";
import wadSection2MobileImage from "../../Assets/ServicesPage/wadSection2MobileImage.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import ContactUsForm from "../ContactUsForm";
import SEO from "../SEO";
const WebApplicationDev = (props) => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };

  //   const handleMouseLeave = () => {
  //     // setActiveCategory(null);
  //   };

  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="wed-app-dev-container">
        <div className="web-app-dev-section1-container">
          <div className="web-app-dev-section1-content">
            <h3>Web Application Development</h3>
            <p>
              Web application development focuses on building dynamic,
              interactive web applications that are accessible through web
              browsers over the internet. This process encompasses responsive
              web app development, designing intuitive user interfaces with
              UI/UX design services, and implementing both server-side
              functionalities and client-side interactions.
            </p>
            <p>
              The goal is to ensure seamless performance across devices,
              utilizing technologies like single-page application development
              and database-driven web applications to deliver an engaging and
              consistent user experience.
            </p>
            <button
              className="wad-page-contact-us-container-button-1"
              onClick={() => {
                navigate("/enquiry-with-us");
                props.scrollToTop();
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="web-app-dev-section1-image">
            <img
              src={wadSection1Image}
              alt="Cutting-edge progressive web app development for seamless user experiences."
            />
          </div>
        </div>
        <div className="web-app-dev-section2-container">
          <div className="web-app-dev-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection2MobileImage}
                alt="Cutting-edge progressive web app development for seamless user experiences."
              />
            ) : (
              <img
                src={wadSection2Image}
                alt="Cutting-edge progressive web app development for seamless user experiences."
              />
            )}
          </div>
          <div className="web-app-dev-section2-content">
            <div className="web-app-dev-section2-content-left-div">
              <h3>When to consider our Web Application Development Service</h3>
              <p>
                We deliver robust software solutions tailored for businesses of
                all sizes, ensuring rapid deployment with a strong focus on
                quality, ROI, and enhancing user experience.
              </p>
              <p>
                Achieve your goals with our custom software solutions, designed
                to align perfectly with your unique business requirements.
                Consider leveraging our end-to-end IT solutions when you need
                enterprise application development, cloud-based application
                development, or responsive web app development to transform your
                business and drive measurable success.
              </p>
            </div>
            <div className="web-app-dev-section2-content-right-div">
              <div className="web-app-dev-section2-content-right-div-grid">
                <h4>Launching a New Web Application</h4>
                <p>
                  If you're launching something new, such as your own app, our
                  software development solutions may give your idea a personal
                  touch, assuring a memorable launch.
                </p>
              </div>
              <div className="web-app-dev-section2-content-right-div-grid">
                <h4>Revamping an Existing Application</h4>
                <p>
                  Refresh your current app with advanced features and
                  functionalities. Our custom software development services
                  revitalize your app, ensuring modernization and increased user
                  engagement.
                </p>
              </div>
              <div className="web-app-dev-section2-content-right-div-grid">
                <h4>Adopting Cross-Platform Development</h4>
                <p>
                  If you're ready to expand the reach of your software, choose
                  cross-platform development with us. We help you broaden the
                  reach of your app.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="web-app-dev-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="web-app-dev-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${
                  category.id === activeCategory.id
                    ? "active-tech-category"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(category)}
                //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="web-app-dev-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="web-app-dev-section4-container">
          <div className="web-app-dev-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Cutting-edge progressive web app development for seamless user experiences."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Cutting-edge progressive web app development for seamless user experiences."
              />
            )}
          </div>
          <div className="web-app-dev-section4-content-layer">
            <div className="web-app-dev-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="web-app-dev-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebApplicationDev;
