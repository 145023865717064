import React, { useState, useReducer } from "react";
import "./Careers.css";
import careersPageSection1MobileImage from "../Assets/Careers/careersPageSection1MobileImage.webp"
import careersPageSection1Image from "../Assets/Careers/careersPageSection1Image.webp";
import uploadIcon from "../Assets/Careers/uploadIcon.svg";
import axios from "axios";
import errorIcon from "../Assets/ServicesPage/errorIcon.svg";
import SuccessModalComponent from "./SuccessModalComponent";
import environment from "../environment";
import SEO from "./SEO";
const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };

  const recruitmentProcessData = [
    {
      id: 1,
      name: "Application Submission",
      description:
        "Submit your application through our user-friendly portal. Share your resume and any relevant documents.",
    },
    {
      id: 2,
      name: "Shortlisting",
      description:
        "Our team reviews applications to identify suitable candidates based on qualifications and values.",
    },
    {
      id: 3,
      name: "Technical Review",
      description:
        "This is a golden opportunity to highlight your skills. Shortlisted candidates have an interview to discuss your background, skills, and aspirations.",
    },
    {
      id: 4,
      name: "HR Interview",
      description:
        "Here's our chance to know each other. We want to understand your background, skills, and behavior.It often covers topics like experience, teamwork, communication, and problem-solving abilities. You can also ask the recruiter about anything you wish to know about the position or Fulminous as an organization.",
    },
    {
      id: 5,
      name: "Decision & Offer",
      description:
        "If the outcome is positive, we'll present you with an offer that includes a compensation structure and official information related to employment at Fulminous Solutions.",
    },
  ];
  // const vacancyListData = [
  //   {
  //     id: 1,
  //     name: "Nodejs Developer",
  //     experience: "1-4",
  //     location: "Hyderabad",
  //     type: "Full time",
  //   },
  //   {
  //     id: 2,
  //     name: "Reactjs Developer",
  //     experience: "1-3",
  //     location: "Hyderabad",
  //     type: "Full time",
  //   },
  //   {
  //     id: 3,
  //     name: "UI/UX Designer",
  //     experience: "1-2",
  //     location: "Hyderabad",
  //     type: "Full time",
  //   },
  //   {
  //     id: 4,
  //     name: "Android Developer",
  //     experience: "1-3",
  //     location: "Hyderabad",
  //     type: "Full time",
  //   },
  //   {
  //     id: 5,
  //     name: "Nodejs Intern",
  //     experience: "0-1",
  //     location: "Hyderabad",
  //     type: "Internship",
  //   },
  //   {
  //     id: 6,
  //     name: "Reactjs Developer Intern",
  //     experience: "0-1",
  //     location: "Hyderabad",
  //     type: "Internship",
  //   },
  // ];
  const [activeDescription, setActiveDescription] = useState(
    recruitmentProcessData[0].description
  );
  const [activeStep, setActiveStep] = useState(1);
  // Handler for file change

  const [fileName, setFileName] = useState("");
  // const [fileDoc, setFileDoc] = useState(null);
  // To store the selected file name
  const handleFileChange = (e) => {
    const file = e.target.files[0];  // Get the first selected file

    if (file) {
      setFileName(file.name);
      // Optional: Validate the file type (PDF, DOC, DOCX) before setting the state
      const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

      if (allowedFileTypes.includes(file.type)) {
        updateResumeDetailsRegister({
          ...resumeDetailsRegister,
          resume: file,  // Store the selected file in the 'resume' field
        });
        console.log("File selected:", file.name);
      } else {
        console.log("Invalid file type. Only .pdf, .doc, and .docx are allowed.");
      }
    } else {
      console.log("No file selected.");
    }
  };
  const [formErrors, setFormErrors] = useState({
    fullname: "",
    email: "",
    message: "",
    experience: "",
    resume: "",
    skills: "",
  });
  const [resumeDetailsRegister, updateResumeDetailsRegister] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      fullname: "",
      email: "",
      experience: "",
      skills: "",
      resume: null,

    }
  );

  const validateForm = () => {
    console.log("validateForm", resumeDetailsRegister.resume)
    let errors = {
      fullname: "",
      email: "",
      experience: "",
      resume: "",
      skills: "",
    };
    let isValid = true;

    // Full name validation (min length 3, alphabetic characters, apostrophes, hyphens)
    let nameRegex = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    if (!resumeDetailsRegister.fullname) {
      errors.fullname = "Required field";
      isValid = false;
    } else if (!nameRegex.test(resumeDetailsRegister.fullname) || resumeDetailsRegister.fullname.length < 3) {
      errors.fullname = "Please enter a valid fullname (min 3 characters)";
      isValid = false;
    }

    // Email validation
    if (!resumeDetailsRegister.email) {
      errors.email = "Required field";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(resumeDetailsRegister.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }

    // Message validation (min 20 characters)
    // if (!resumeDetailsRegister.message) {
    //   errors.message = "Required field";
    //   isValid = false;
    // } else if (resumeDetailsRegister.message.length < 20) {
    //   errors.message = "Message must be at least 20 characters long";
    //   isValid = false;
    // }

    // Experience validation (should be a positive number)
    if (!resumeDetailsRegister.experience) {
      errors.experience = "Required field";
      isValid = false;
    } else if (isNaN(resumeDetailsRegister.experience) || resumeDetailsRegister.experience <= 0) {
      errors.experience = "Please enter valid experience in years";
      isValid = false;
    }

    // Skills validation (non-empty)
    if (!resumeDetailsRegister.skills) {
      errors.skills = "Required field";
      isValid = false;
    }

    // Resume validation (must be a .pdf, .doc, or .docx file)
    const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    if (!resumeDetailsRegister.resume) {
      errors.resume = "Resume is required";
      isValid = false;
    } else if (!allowedFileTypes.includes(resumeDetailsRegister.resume.type)) {
      errors.resume = "Only .pdf, .doc, or .docx files are allowed";
      isValid = false;
    }

    // Set the errors
    setFormErrors(errors);

    // Return the validity status
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("Submit");
    e.preventDefault();

    let submitSuggestionUrl = environment.baseUrl + "/api/user/fileupload";
    const formData = new FormData();
    formData.append("fullName", resumeDetailsRegister.fullname || "");
    formData.append("email", resumeDetailsRegister.email || "");
    formData.append("experience", resumeDetailsRegister.experience || "");
    formData.append("skills", resumeDetailsRegister.skills || "");
    formData.append("resume", resumeDetailsRegister.resume || "");

    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    if (validateForm()) {
      let res = await axios.post(submitSuggestionUrl, formData);

      let resData = res.data;
      if (resData.status === 200) {
        updateResumeDetailsRegister({
          fullname: "",
          email: "",
          message: "",
          experience: "",
          resume: "",
          skills: "",
        });
        setFormErrors({
          fullname: "",
          email: "",
          message: "",
          experience: "",
          resume: "",
          skills: "",
        });
        setFileName('')
        setIsModalOpen(true);
        // setEnableNextScreen(true);
      }
    } else {
      console.log("Please enter valid details");
    }
  };

  return (
    <React.Fragment>
      <SEO
        title="Careers"
        description="Join the CREXIN team and grow your career with a leader in digital transformation. Explore opportunities to work with cutting-edge tech, in a dynamic and supportive environment."
      />
      <div className="careers-page-container">
        <div className="careers-page-section1-container">
          <div className="careers-page-section1-image">
            {window.innerWidth <= 430 ? <img src={careersPageSection1MobileImage} alt="Expert IT consulting and solutions for your business challenges." /> : <img src={careersPageSection1Image} alt="Expert IT consulting and solutions for your business challenges." />}
          </div>
          <div className="careers-page-section1-content-layer">
            <div className="careers-page-section1-content">
              <div className="careers-page-section1-resume-form-div">
                <p>Fill out the form below to submit your application, and we'll be in touch if there's a fit!</p>
                <form className="needs-validation careers-page-section1-resume-form" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className="input-row">
                    <div className="input-group fullname-group">
                      <input
                        type="text"
                        placeholder="Full Name *"
                        className={`form-control formdata formdata-fullname ${formErrors.fullname && "is-invalid"
                          }`}
                        id="validationCustomUsername"
                        required={true}
                        value={
                          resumeDetailsRegister.fullname
                            ? resumeDetailsRegister.fullname
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, fullname: "" });
                        }}
                        onChange={(e) => {
                          updateResumeDetailsRegister({
                            fullname: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.fullname
                            ? "invalid-feedback fullname-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" src={errorIcon} className="error-icon" />
                        <p>{formErrors.fullname}</p>
                      </div>
                    </div>
                    <div className="input-group email-group">
                      <input
                        type="text"
                        placeholder="Email Address*"
                        className={`form-control formdata formdata-email ${formErrors.email && "is-invalid"
                          }`}
                        // id="validationCustomUsername"
                        required={true}
                        value={
                          resumeDetailsRegister.email
                            ? resumeDetailsRegister.email
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, email: "" });
                        }}
                        onChange={(e) => {
                          updateResumeDetailsRegister({
                            email: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.email
                            ? "invalid-feedback email-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" src={errorIcon} className="error-icon" />
                        <p>{formErrors.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="input-row-2">
                    <div className="input-group experience-group">
                      <input
                        type="text"
                        placeholder="Years of Experience *"
                        className={`form-control formdata formdata-experience ${formErrors.experience && "is-invalid"
                          }`}
                        id="validationCustomUsername"
                        required={true}
                        value={
                          resumeDetailsRegister.experience
                            ? resumeDetailsRegister.experience
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, experience: "" });
                        }}
                        onChange={(e) => {
                          updateResumeDetailsRegister({
                            experience: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.experience
                            ? "invalid-feedback experience-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" src={errorIcon} className="error-icon" />
                        <p>{formErrors.experience}</p>
                      </div>
                    </div>
                    <div className="input-group skills-group">
                      <input
                        type="text"
                        placeholder="Skills *"
                        className={`form-control formdata formdata-skills ${formErrors.skills && "is-invalid"
                          }`}
                        id="validationCustomUsername"
                        required={true}
                        value={
                          resumeDetailsRegister.skills
                            ? resumeDetailsRegister.skills
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, skills: "" });
                        }}
                        onChange={(e) => {
                          updateResumeDetailsRegister({
                            skills: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.skills
                            ? "invalid-feedback skills-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" src={errorIcon} className="error-icon" />
                        <p>{formErrors.skills}</p>
                      </div>
                    </div>
                  </div>

                  <div className="input-resume-row">
                    <div className="input-group resume-group">
                      <input
                        type="file"
                        id="file-input"
                        accept=".doc,.docx,.pdf"
                        onChange={handleFileChange}
                        className={`form-control formdata formdata-resume ${formErrors.resume && "is-invalid"
                          }`}
                      />
                      <label htmlFor="file-input" className="file-input-label">
                        <img
                          className="file-input-icon"
                          src={uploadIcon}
                          alt="upload"
                        />
                        {fileName ? fileName : "Upload Resume"}
                      </label>

                    </div>
                    <div
                      className={
                        formErrors.resume
                          ? "invalid-feedback resume-feedback"
                          : "valid-feedback"
                      }
                    >
                      <img alt="error" src={errorIcon} className="error-icon" />
                      <p>{formErrors.resume}</p>
                    </div>
                  </div>
                  <div className="submit-button-div">
                    <button
                      className="careers-page-form-submit-button"
                      type="submit"

                    >
                      <p>SUBMIT</p>
                    </button>
                  </div>
                </form>
              </div>
              <div className="careers-page-section1-side-description">
                <h4>Elevate your career with CREXIN</h4>
                <p>Submit your Resume to careers@crexin.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="careers-page-section2-container">
          <div className="careers-page-section2-title-container">
            <h3>
              Our <span>Recruitment Process</span>
            </h3>
          </div>
          <div className="careers-page-section2-description">
            <p>
              Putting people first begins the moment we meet. Share your talents
              and ambitions with us. Discover employment possibilities that
              resonate with your path.
            </p>
            <p>Our simple five-step recruitment procedure.</p>
          </div>
          <div className="careers-page-section2-recruitment-process-container">
            <div className="careers-page-section2-recruitment-process-steps">
              {recruitmentProcessData.map((item) => (
                <div
                  className="recruitment-step"
                  key={item.id}
                  onMouseEnter={() => {
                    setActiveDescription(item.description);
                    setActiveStep(item.id);
                  }}
                  onMouseLeave={() => {
                    setActiveDescription(recruitmentProcessData[0].description);
                    setActiveStep(1);
                  }}
                >
                  <div
                    className={`recruitment-step-id ${activeStep === item.id ? "active-step-id" : ""
                      }`}
                  >
                    <p>{item.id}</p>
                  </div>
                  <p
                    className={`recruitment-step-name ${activeStep === item.id ? "active-step-name" : ""
                      }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
            <div className="recruitment-process-step-description">
              <p>{activeDescription}</p>
            </div>
          </div>
        </div>
        {/* <div className="careers-page-section3-container">
        <div className="careers-page-section1-image">
          <img src={careersPageSection3Image} alt="careers" />
        </div>
        <div className="careers-page-section3-content">
          <h3>Explore our Latest Vacancies</h3>
          <div className="careers-page-section3-vacancies-grid">
            {vacancyListData.map((vacancy, index) => {
              return (
                <div
                  key={vacancy.id}
                  className={`careers-page-section3-vacancy-grid-item vacancy-grid-item${vacancy.id}`}
                >
                  <div className="careers-page-section3-vacancy-grid-description">
                    <p>{vacancy.type}</p>
                    <h3>{vacancy.name}</h3>
                    <p>
                      <span>
                        <img src={experienceIcon} alt="experience" />
                      </span>
                      {vacancy.experience} years Exp. &nbsp; &nbsp;
                      <span>
                        <img src={locationIcon} alt="location" />
                      </span>
                      {vacancy.location}
                    </p>
                  </div>
                  <div
                    className="careers-page-section3-vacancy-grid-apply-now-button"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    Apply Now
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
        <SuccessModalComponent
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default Careers;
