import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CrexinLogo from "../Assets/Homepage/LOGO.svg";
import Envelope from "../Assets/Homepage/envelope.svg";
import { MenuContext } from "../MenuContext";
import toggleButton from "../Assets/Homepage/toggleButton.svg";
import toggleCloseButton from "../Assets/Homepage/toggleCloseButton.svg";
const Header = () => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);
  const navigate = useNavigate();

  const toggleNavbarMenu = () => {
    console.log("toggleNavbarMenu");
    toggleMenu(!isMenuOpen);
  };
  return (
    <div>
      <div className="header-container">
        <div className="navbar-container">
          <div
            className="crexin-logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={CrexinLogo} alt="crexin logo" />
          </div>
          <div className="navbar-info-container">
            <div className="navbar">
              <div
                className={`nav-link ${window.location.pathname === "/our-products"
                    ? "nav-link-active"
                    : ""
                  }`}
                onClick={() => {
                  navigate("/our-products");
                }}
              >
                Our Products
              </div>
              <div
                className={`nav-link ${window.location.pathname === "/our-services"
                    ? "nav-link-active"
                    : ""
                  }`}
                onClick={() => {
                  navigate("/our-services");
                }}
              >
                Services
              </div>
              <div
                className={`nav-link ${window.location.pathname === "/about-us"
                    ? "nav-link-active"
                    : ""
                  }`}
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </div>
              <div
                className={`nav-link ${window.location.pathname === "/careers"
                    ? "nav-link-active"
                    : ""
                  }`}
                onClick={() => {
                  navigate("/careers");
                }}
              >
                Careers
              </div>
              <div className={`nav-link ${window.location.pathname === "/blogs"
                ? "nav-link-active"
                : ""
                }`}
                onClick={() => {
                  navigate("/blogs");
                }}>Blogs</div>
              <div
                className={`nav-link ${window.location.pathname === "/enquiry-with-us"
                    ? "nav-link-active"
                    : ""
                  }`}
                onClick={() => {
                  navigate("/enquiry-with-us");
                }}
              >
                Enquiry
              </div>
            </div>
            <div className="enquriy-div">
              <img src={Envelope} alt="envelope-icon" />
              <a className="contact-email" href="mailto:info@crexin.com">
                info@crexin.com
              </a>
            </div>
          </div>
        </div>
        <div className="navbar-mobile-container">
          <div
            className="crexin-logo"
            onClick={() => {
              navigate("/");
              isMenuOpen && toggleNavbarMenu();
            }}
          >
            <img src={CrexinLogo} alt="crexin logo" />
          </div>
          <div
            className="toggle-button-div"
            onClick={() => {
              toggleNavbarMenu();
            }}
          >
            {!isMenuOpen ? (
              <img alt="" src={toggleButton} />
            ) : (
              <img alt="" src={toggleCloseButton} />
            )}
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        {isMenuOpen ? (
          <div className="mobile-nav-menu-div">
            <p
              className="nav-link"
              onClick={() => {
                navigate("/our-products");
                toggleMenu();
              }}
            >
              Our Products
            </p>
            <p
              className="nav-link"
              onClick={() => {
                navigate("/our-services");
                toggleMenu();
              }}
            >
              Services
            </p>
            <p
              className="nav-link"
              onClick={() => {
                navigate("/about-us");
                toggleMenu();
              }}
            >
              About Us
            </p>
            <p
              className="nav-link"
              onClick={() => {
                navigate("/careers");
                toggleMenu();
              }}
            >
              Careers
            </p>
            <p
              className="nav-link"
              onClick={() => {
                console.log("HI!");
                navigate("/enquiry-with-us");
                toggleMenu();
              }}
            >
              Enquiry
            </p>
            <p
              className="nav-link"
              onClick={() => {
                navigate("/blogs");
                toggleMenu();
              }}
            >
             Blogs
            </p>
            <p className="nav-link nav-link-div">
              <img
                className="envelope-icon"
                src={Envelope}
                alt="envelope-icon"
              />
              <a className="contact-email" href="mailto:info@crexin.com">
                info@crexin.com
              </a>
            </p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Header;
