import React from "react";
import arrowIcon from "../Assets/Homepage/arrowIcon.svg";
import CrexinTextLogo from "../Assets/Homepage/CrexinTextLogo.svg";
import copyRightIcon from "../Assets/Homepage/copyRightIcon.svg";
import facebookIcon from "../Assets/Homepage/facebookIcon.svg";
import instagramIcon from "../Assets/Homepage/instagramIcon.svg";
import linkedinIcon from "../Assets/Homepage/linkedInIcon.svg";
import twitterXIcon from "../Assets/Homepage/twitterXIcon.svg";
import youtubeIcon from "../Assets/Homepage/youtubeIcon.svg";
import { useNavigate } from "react-router-dom";
const Footer = (props) => {
  const navigate = useNavigate();
  const openExternalLink = (url) => {
    window.open(url, "_blank"); // Opens in a new tab
  };
  return (
    <div className="footer-main-container">
      <div className="footer-top-container">
        <div className="get-in-touch-with-us-container">
          <div className="get-in-touch-with-us-container-description"> <h4>Get in touch with us</h4>
          <p>For more details and enquiry please click the below button.</p></div>
          <button
            className="get-in-touch-with-us-container-button"
            onClick={() => {
              navigate("/enquiry-with-us");
              props.scrollToTop();
            }}
          >
            <p>Let's Chat</p>
            <img src={arrowIcon} alt="lets chat" />
          </button>
        </div>
        <div className="footer-links-container">
          <div className="footer-services-div">
            <h5
              onClick={() => {
                navigate("/our-services");
                props.scrollToTop();
              }}
            >
              Services
            </h5>
            <p
              onClick={() => {
                navigate("/our-services/web-application-development");
                props.scrollToTop();
              }}
            >
              Web Application Development
            </p>
            <p
              onClick={() => {
                navigate("/our-services/mobile-application-development");
                props.scrollToTop();
              }}
            >
              Mobile Application Development
            </p>
            <p
              onClick={() => {
                navigate("/our-services/website-design-development");
                props.scrollToTop();
              }}
            >
              Website Design & Development
            </p>
            <p
              onClick={() => {
                navigate("/our-services/digital-marketing-services");
                props.scrollToTop();
              }}
            >
              Digital Marketing
            </p>
            <p
              onClick={() => {
                navigate("/our-services/ui-ux-design-services");
                props.scrollToTop();
              }}
            >
              UI/UX Design
            </p>
            <p
              onClick={() => {
                navigate("/our-services/graphic-design-services");
                props.scrollToTop();
              }}
            >
              Graphic Design
            </p>
            <p
              onClick={() => {
                navigate("/our-services/microsoft-application-services");
                props.scrollToTop();
              }}
            >
              Microsoft Application Services
            </p>
            <p
              onClick={() => {
                navigate("/our-services/software-maintenance-services");
                props.scrollToTop();
              }}
            >
              Software Maintanence & Support
            </p>
          </div>
          <div className="footer-company-div">
            <h5>Company</h5>
            <p
              onClick={() => {
                navigate("/about-us");
                props.scrollToTop();
              }}
            >
              About Us
            </p>
            <p
              onClick={() => {
                navigate("/blogs");
                props.scrollToTop();
              }}
            >
              Blogs
            </p>
            <p
              onClick={() => {
                navigate("/careers");
                props.scrollToTop();
              }}
            >
              Careers
            </p>
            <p
              onClick={() => {
                navigate("/our-products");
                props.scrollToTop();
              }}
            >
              Our Products
            </p>
            <p
              onClick={() => {
                navigate("/enquiry-with-us");
                props.scrollToTop();
              }}
            >
              Enquiry
            </p>
            <p
              onClick={() => {
                navigate("/privacy-policy");
                props.scrollToTop();
              }}
            >
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
      <div className="footer-divider-line"></div>
      <div className="footer-bottom-container">
        <div className="footer-bottom-container-left-div">
          <div
            className="company-logo-div"
            onClick={() => {
              navigate("/");
              props.scrollToTop();
            }}
          >
            <img src={CrexinTextLogo} alt="crexin logo" />
          </div>
          <div className="copyright-div">
            <p>Copyright</p>
            <img src={copyRightIcon} alt="c" />{" "}
            <p>2024 Crexin Technologies. All rights reserved.</p>
          </div>
        </div>
        <div className="footer-bottom-container-right-div">
          <div className="socail-media-tray">
            <img
              src={facebookIcon}
              alt="facebook"
              onClick={() => {
                openExternalLink(
                  "https://www.facebook.com/people/CREXIN-Technologies/100082992024149/"
                );
              }}
            />
            <img
              src={instagramIcon}
              alt="instagram"
              onClick={() => {
                openExternalLink("https://www.instagram.com/crexin_tech/");
              }}
            />
            <img
              src={linkedinIcon}
              alt="linkedin"
              onClick={() => {
                openExternalLink(
                  "https://www.linkedin.com/company/crexin-technologies/"
                );
              }}
            />
            <img
              src={twitterXIcon}
              alt="twitter"
              onClick={() => {
                openExternalLink("https://x.com/CrexinTech");
              }}
            />
            <img
              src={youtubeIcon}
              alt="youtube"
              onClick={() => {
                openExternalLink("https://www.youtube.com/@crexintechnologies");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
