import React, { useState } from "react";
import "./App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Header from "./Common/Header";
import Homepage from "./Components/Homepage";
import Footer from "./Common/Footer";
import OurProducts from "./Components/OurProducts";
import ServicesPage from "./Components/ServicesPage";
import Enquiry from "./Components/Enquiry";
import AboutUs from "./Components/AboutUs";
import Careers from "./Components/Careers";
import WebApplicationDev from "./Components/ServicePages/WebApplicationDev";
import MobileApplicationDev from "./Components/ServicePages/MobileApplicationDev";
import WebsiteDesignDev from "./Components/ServicePages/WebsiteDesignDev";
import DigitalMarketingSerivces from "./Components/ServicePages/DigitalMarketingSerivces";
import UiUxDesignServices from "./Components/ServicePages/UiUxDesignServices";
import GraphicDesignServices from "./Components/ServicePages/GraphicDesignServices";
import MicrosoftApplicationService from "./Components/ServicePages/MicrosoftApplicationService";
import SoftwareMaintenanceServices from "./Components/ServicePages/SoftwareMaintenanceServices";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Blogs from "./Components/Blogs";
import { MenuProvider } from "./MenuContext";
import { HelmetProvider } from "react-helmet-async";
import MobileFirstDesign from "./Components/Blogs/MobileFirstDesign";
import SdlcOverview from "./Components/Blogs/SdlcOverview";
import ChoosingTheRightPathPocPrototypeMvp from "./Components/Blogs/ChoosingTheRightPathPocPrototypeMvp";
import ControlSoftwareDevelopmentBudget from "./Components/Blogs/ControlSoftwareDevelopmentBudget";
import StaticVsDynamicWebAppDevelopment from "./Components/Blogs/StaticVsDynamicWebAppDevelopment";
import TechStack from "./Components/Blogs/TechStack";
import CrossPlatformVsNativeAppDev from "./Components/Blogs/CrossPlatformVsNativeAppDev";
const App = () => {
  const helmetContext = {};
  const [countryCodesList, setCountryCodesList] = useState([]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };

  const getCountryCodes = async () => {
    let res = await axios.get("https://restcountries.com/v3.1/all");
    let resData = res.data;

    const filteredData = resData
      .map((country) => {
        const root = country.idd ? country.idd.root : "";
        const suffix =
          country.idd && country.idd.suffixes ? country.idd.suffixes[0] : "";

        return {
          countryCode: country.cca2, // Country's 2-letter code
          dialCode: root + suffix, // Full dialing code (root + first suffix)
        };
      })
      .filter((country) => country.dialCode);

    setCountryCodesList(...filteredData);
  };
  // getCountryCodes();

  // console.log(countryCodesList);

  return (
    <MenuProvider>
      <div className="App">
        <div className="app-container">
          <BrowserRouter>
            <Header />
            <HelmetProvider context={helmetContext}>
              <Routes>
                <Route
                  path="/"
                  element={<Homepage scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-products"
                  element={<OurProducts scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services"
                  element={<ServicesPage scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/enquiry-with-us"
                  element={<Enquiry scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/about-us"
                  element={<AboutUs scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/careers"
                  element={<Careers scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/web-application-development"
                  element={<WebApplicationDev scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/mobile-application-development"
                  element={<MobileApplicationDev scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/website-design-development"
                  element={<WebsiteDesignDev scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/digital-marketing-services"
                  element={
                    <DigitalMarketingSerivces scrollToTop={scrollToTop} />
                  }
                />
                <Route
                  path="/our-services/ui-ux-design-services"
                  element={<UiUxDesignServices scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/graphic-design-services"
                  element={<GraphicDesignServices scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/our-services/microsoft-application-services"
                  element={
                    <MicrosoftApplicationService scrollToTop={scrollToTop} />
                  }
                />
                <Route
                  path="/our-services/software-maintenance-services"
                  element={
                    <SoftwareMaintenanceServices scrollToTop={scrollToTop} />
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/blogs"
                  element={<Blogs scrollToTop={scrollToTop} />}
                />

                <Route
                  path="/blogs/importance-of-mobile-first-design-in-modern-projects"
                  element={<MobileFirstDesign scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/blogs/software-development-life-cycle-overview"
                  element={<SdlcOverview scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/blogs/poc-prototype-mvp-choosing-the-right-path"
                  element={
                    <ChoosingTheRightPathPocPrototypeMvp
                      scrollToTop={scrollToTop}
                    />
                  }
                />
                <Route
                  path="/blogs/how-to-control-your-software-development-budget"
                  element={
                    <ControlSoftwareDevelopmentBudget
                      scrollToTop={scrollToTop}
                    />
                  }
                />
                <Route
                  path="/blogs/static-vs-dynamic-web-app-development-which-is-right-for-your-project"
                  element={
                    <StaticVsDynamicWebAppDevelopment
                      scrollToTop={scrollToTop}
                    />
                  }
                />
                <Route
                  path="/blogs/how-to-choose-the-right-tech-stack-for-your-website-development"
                  element={<TechStack scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/blogs/cross-platform-vs-native-app-development-which-should-you-choose"
                  element={
                    <CrossPlatformVsNativeAppDev scrollToTop={scrollToTop} />
                  }
                />
              </Routes>
            </HelmetProvider>
            {window.location.pathname !== "/enquiry-with-us" ? (
              <Footer scrollToTop={scrollToTop} />
            ) : (
              ""
            )}
          </BrowserRouter>
          <CookieConsent
          className="cookies-consent-container"
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Decline"
          enableDeclineButton
          cookieName="best-budget"
          style={{
            background: "#1080e3",
            height:window.innerWidth > 430 ?"auto": "120px",
            width: window.innerWidth > 430 ? "1532px" : "100%",
            left: window.innerWidth > 430 ? "auto" : "0px",
            textAlign: window.innerWidth > 430 ? "center" : "center",
            fontSize: window.innerWidth > 430 ? "16px" : "12px",
            margin: window.innerWidth > 430 ? "0px auto" : "0px auto",
            display: "flex", // Use flexbox
            // flexDirection: window.innerWidth > 430 ? "row" : "column", // Stack buttons vertically on mobile
            justifyContent: "center", // Center buttons horizontally
            alignItems: "center",

          }}
          buttonStyle={{
            background: "#47AF3F",
            color: "#FFFFFF",
            fontSize: window.innerWidth > 430 ? "16px" : "12px",
            borderRadius: "5px",
            margin: window.innerWidth > 430 ? "20px 20px" : "10px 10px",
          }}
          declineButtonStyle={{
            fontSize: window.innerWidth > 430 ? "16px" : "12px",
            borderRadius: "5px",
            margin: window.innerWidth > 430 ? "20px 20px" : "10px 10px",
          }}
          expires={150}
          onAccept={() => {
            console.log("Cookies accepted");
          }}
          onDecline={() => {
            console.log("Cookies declined");
          }}
        >
          We use cookies to improve your experience. By continuing to use our
          website, you agree to our use of cookies.{" "}
          <a href="/privacy-policy" style={{ color: "#FFD700" }}>
            Learn More
          </a>
        </CookieConsent>
        </div>
      </div>
    </MenuProvider>
  );
};

export default App;
