import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SoftwareMaintenanceServices.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import smsSection1Image from "../../Assets/ServicesPage/smsSection1Image.webp";
import smsSection2Image from "../../Assets/ServicesPage/smsSection2Image.webp";
import smsSection2MobileImage from "../../Assets/ServicesPage/smsSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";

const SoftwareMaintenanceServices = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="sw-main-ser-container">
        <div className="sw-main-ser-section1-container">
          <div className="sw-main-ser-section1-content">
            <h3>Software Maintenance & Support Services</h3>
            <p>
              Software maintenance and support services are crucial for ensuring
              that your applications and systems remain functional, secure, and
              up-to-date. These services help businesses minimize downtime,
              optimize performance, and adapt to evolving business needs over
              time.
            </p>
            <p>
              Whether you require regular updates, troubleshooting, or
              enhancements, our software maintenance services are vital for
              sustaining the long-term success of your software investments. By
              leveraging these services, organizations can maintain the
              reliability of their custom software solutions and ensure seamless
              operation as they scale.
            </p>
            <button
              className="wad-page-contact-us-container-button-2"
              onClick={() => {
                navigate("/enquiry-with-us");
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="sw-main-ser-section1-image">
            <img
              src={smsSection1Image}
              alt="Comprehensive end-to-end IT solutions for businesses of all sizes."
            />
          </div>
        </div>
        <div className="sw-main-ser-section2-container">
          <div className="sw-main-ser-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={smsSection2MobileImage}
                alt="Comprehensive end-to-end IT solutions for businesses of all sizes."
              />
            ) : (
              <img
                src={smsSection2Image}
                alt="Comprehensive end-to-end IT solutions for businesses of all sizes."
              />
            )}
          </div>
          <div className="sw-main-ser-section2-content">
            <div className="sw-main-ser-section2-content-left-div">
              <h3>
                When to consider our Software Maintenance & Support Service
              </h3>
              <p>
                Supporting and maintaining legacy systems is essential for
                businesses that rely on older software to power critical
                operations. Our services ensure that these legacy systems
                continue to function optimally and meet business needs, even as
                technology evolves.
              </p>
              <p>
                By investing in legacy system support and software maintenance
                services, businesses can reduce downtime, improve performance,
                and adapt to shifting demands. This approach not only extends
                the life of their software investments but also ensures that
                systems remain secure and capable of supporting business process
                optimization over time.
              </p>
              {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
            </div>
            <div className="sw-main-ser-section2-content-right-div">
              <div className="sw-main-ser-section2-content-right-div-grid">
                <h4>Continuous Improvement</h4>
                <p>
                  If your business relies on software that needs regular
                  updates, enhancements, or bug fixes, ongoing maintenance and
                  support services are essential to keep it running smoothly.
                </p>
              </div>
              <div className="sw-main-ser-section2-content-right-div-grid">
                <h4>Compliance and Security</h4>
                <p>
                  When your software needs to comply with new regulations or
                  enhance its security, maintenance services can ensure that it
                  meets these requirements.
                </p>
              </div>
              <div className="sw-main-ser-section2-content-right-div-grid">
                <h4>Business Growth</h4>
                <p>
                  As your business grows, you may need to adapt your software to
                  new needs, add features, or integrate with new systems, all of
                  which require professional maintenance and support.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sw-main-ser-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="sw-main-ser-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${
                  category.id === activeCategory.id
                    ? "active-tech-category"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(category)}
                //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="sw-main-ser-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="sw-main-ser-section4-container">
          <div className="sw-main-ser-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Comprehensive end-to-end IT solutions for businesses of all sizes."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Comprehensive end-to-end IT solutions for businesses of all sizes."
              />
            )}
          </div>
          <div className="sw-main-ser-section4-content-layer">
            <div className="sw-main-ser-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="sw-main-ser-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SoftwareMaintenanceServices;
