import React from "react";
import "./OurProducts.css";
import ourProductsBackgroundImage from "../Assets/OurProducts/ourProductsBackgroundImage.webp";
import arrowIcon from "../Assets/Homepage/arrowIcon.svg";
import playStoreIcon from "../Assets/OurProducts/playStoreIcon.svg";
import budgetScreenshot from "../Assets/OurProducts/budgetScreenshot.webp";
import bestBudgetFirstScreen from "../Assets/OurProducts/bestBudgetFirstScreen.webp";
import bestBudgetDashboardScreen from "../Assets/OurProducts/bestBudgetDashboardScreen.webp";
import bestBudgetAccountsScreen from "../Assets/OurProducts/bestBudgetAccountsScreen.webp";
import bestbudgetFeaturesImage from "../Assets/OurProducts/section2Image.webp";
import budgetIcon from "../Assets/OurProducts/budgetIcon.svg";
import recurringBillsIcon from "../Assets/OurProducts/recurringBillsIcon.svg";
import customizationIcon from "../Assets/OurProducts/customizationIcon.svg";
import reminderIcon from "../Assets/OurProducts/reminderIcon.svg";
import reportsIcon from "../Assets/OurProducts/reportsIcon.svg";
import oldCouple from "../Assets/OurProducts/oldCouple.svg";
import happyPeople from "../Assets/OurProducts/happyPeople.svg";
import moneyBag from "../Assets/OurProducts/moneyBag.svg";
import manStanding from "../Assets/OurProducts/manStanding.svg";
import ourProductsBackgroundMobileImage from "../Assets/OurProducts/ourProductsBackgroundMobileImage.webp";
import SEO from "./SEO";
const OurProducts = () => {
  const openExternalLink = (url) => {
    window.open(url, "_blank"); // Opens in a new tab
  };
  return (
    <React.Fragment>
      <SEO
        title="Our Products - Best Budget"
        description=
          "Best Budget is your ultimate personal finance mobile application, designed to simplify money management and empower you to effortlessly achieve your financial goals using effective budgeting methods."
        
      />
      <div className="our-products-container">
        <div className="our-products-section1-container">
          <div className="our-products-section1-background-image">
            {window.innerWidth <= 430 ? (
              <img
                src={ourProductsBackgroundMobileImage}
                alt="homepage-image"
              />
            ) : (
              <img src={ourProductsBackgroundImage} alt="homepage-image" />
            )}
          </div>
          <div className="our-products-background-layer">
            <div className="bestbudget-description-container">
              <h2>BEST BUDGET</h2>
              <p>
                Best Budget is your ultimate personal finance mobile
                application, designed to simplify money management and empower
                you to effortlessly achieve your financial goals using effective
                budgeting methods.
              </p>
              <button
                className="our-products-explore-now-container-button"
                onClick={() => {
                  openExternalLink("https://bestbudget.in");
                }}
              >
                <p>Explore it now</p>
                <img src={arrowIcon} alt="lets chat" />
              </button>
              <div
                className="playstore-icon-div"
                onClick={() => {
                  openExternalLink("https://play.google.com/store/");
                }}
              >
                <img src={playStoreIcon} alt="playstore-icon" />
              </div>
            </div>
            <div className="our-products-app-screnshots-layer">
              <div className="our-products-app-screenshot1">
                <img src={budgetScreenshot} alt="dashboard screenshot" />
              </div>
              <div className="our-products-app-screenshot2">
                <img
                  src={bestBudgetFirstScreen}
                  alt="bestBudgetFirstScreen screenshot"
                />
                <img
                  src={bestBudgetAccountsScreen}
                  alt="bestBudgetFirstScreen screenshot"
                />
              </div>
              <div className="our-products-app-screenshot1">
                <img
                  src={bestBudgetDashboardScreen}
                  alt="dashboard screenshot"
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="our-products-section2-container">
          <div className="our-products-section2-left-div">
            <h3>THE PERFECT FEATURES FOR YOU:</h3>
            <div className="our-products-section2-left-div-image">
              <img
                src={bestbudgetFeaturesImage}
                alt="Achieve financial peace of mind with best budget"
              />
            </div>
          </div>
          <div className="our-products-section2-right-div">
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid1">
              <img src={budgetIcon} alt="personal finance application" />
              <h6>BUDGET</h6>
              <p>
                Set a budget for each category of expense and receive a reminder
                when you are close to exceeding your budget.
              </p>
            </div>
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid2">
              <img
                src={recurringBillsIcon}
                alt="personal finance application"
              />
              <h6>RECURRING BILLS</h6>
              <p>
                Manage all your credit cards in one place and escape interest.
                Plan to pay off every month.
              </p>
            </div>
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid3">
              <img src={customizationIcon} alt="personal finance application" />
              <h6>CUSTOMIZATION</h6>
              <p>
                It’s made to fit your specific needs and preferences, helping
                you do things in a way that suits you best.
              </p>
            </div>
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid4">
              <img src={reminderIcon} alt="personal finance application" />
              <h6>REMINDER</h6>
              <p>
                Best Budget will remind you when your bills are due so you'll never
                miss a payment again.
              </p>
            </div>
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid5">
              <img src={reportsIcon} alt="personal finance application" />
              <h6>REPORTS</h6>
              <p>
                Utilize interactive charts to monitor your financial progress
                and make well-informed decisions regarding your finances.
              </p>
            </div>
            <div className="our-products-section2-right-div-grid our-products-section2-right-div-grid6">
              <img src={reportsIcon} alt="personal finance application" />
              <h6>DASHBOARD</h6>
              <p>
                Get an overview of your budgets, top expenses, top payees and
                balances at a glance.
              </p>
            </div>
          </div>
        </div>
        <div className="our-products-section3-container">
          <div className="our-products-section3-bg-layer"></div>
          <div className="our-products-section3-content-container">
            <h4>
              Stop using spreadsheets and start tracking your money with the
              best expense tracker app available.
            </h4>
            <p>
              With BestBudget, you can effortlessly track your income and
              expenses, ensuring you stay within your budget and never miss a
              payment. This user-friendly Android app is your ultimate financial
              companion, empowering you to take control of your finances and
              achieve your financial goals.
            </p>
            <h2>HOW DOES IT WORK</h2>
            <div className="our-products-section3-grid-container">
              <div className="our-products-section3-grid grid1">
                <h1>01</h1>
                <h4>Set up your budget</h4>
                <p>
                  Create and manage custom budgets that perfectly work for you.
                  No more surprises at the end of the month!
                </p>
              </div>

              <div className="our-products-section3-grid grid1">
                <h1>02</h1>
                <h4>Add your transactions</h4>
                <p>
                  Including those that can be easily forgotten, with just one
                  tap. You can set your expenses as paid, pending, recurring, or
                  repeated.
                </p>
              </div>
              <div className="our-products-section3-grid grid1">
                <h1>03</h1>
                <h4>Track your balance</h4>
                <p>
                  Check your balance in the dashboard and know exactly where
                  your money is going.
                </p>
              </div>
              <div className="our-products-section3-grid grid1">
                <h1>04</h1>
                <h4>Save money</h4>
                <p>
                  Create and manage custom budgets that perfectly work for you.
                  No more surprises at the end of the month!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="our-products-section4-container">
          <h3>What you will accomplish using BestBudget</h3>
          <div className="our-products-section4-content">
            <div className="our-products-section4-left-div">
              <p>
                Stop wondering where your hard-earned money disappears. The
                BestBudget Method for managing finances will help you grow your
                savings and make you love the way you spend your money.
              </p>
              <p>
                We’re committed to offering you a straightforward and
                user-friendly budgeting experience. We know that managing
                finances can feel overwhelming, so we’ve crafted our application
                to be accessible for everyone, no matter their level of
                financial expertise.
              </p>
            </div>
            <div className="our-products-section4-right-div">
              <div className="our-products-section4-grid our-products-section4-grid1">
                <img src={oldCouple} alt="old couple feeling happy" />
                <h4>Be Less Worried About Expenses</h4>
              </div>
              <div className="our-products-section4-grid our-products-section4-grid2">
                <img
                  src={happyPeople}
                  alt="happy feeling after organising finances"
                />
                <h4>Organize your finances</h4>
              </div>
              <div className="our-products-section4-grid our-products-section4-grid3">
                <img src={moneyBag} alt="saving more money" />
                <h4>Save more money</h4>
              </div>
              <div className="our-products-section4-grid our-products-section4-grid4">
                <img src={manStanding} alt="feeling confident" />
                <h4>Feel Confident with an irregular income</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurProducts;
