import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ServicesPage.css";
import servicePageBackgroundImage from "../Assets/ServicesPage/servicePageBackgroundImage.webp";
import servicePageBackgroundMobileImage from "../Assets/ServicesPage/servicePageBackgroundMobileImage.webp";
import WAD from "../Assets/Homepage/WAD.webp";
import MAD from "../Assets/Homepage/MAD.webp";
import WDD from "../Assets/Homepage/WDD.webp";
import DMS from "../Assets/Homepage/DMS.webp";
import UIUX from "../Assets/Homepage/UIUX.webp";
import GDS from "../Assets/Homepage/GDS.webp";
import MAS from "../Assets/Homepage/MAS.webp";
import arrowIcon from "../Assets/Homepage/arrowIcon.svg";
import projectDiscoveryIcon from "../Assets/ServicesPage/projectDiscoveryIcon.svg";
import softwarePrototypeIcon from "../Assets/ServicesPage/softwarePrototypeIcon.svg";
import technologyEvaluationIcon from "../Assets/ServicesPage/technologyEvaluationIcon.svg";
import softwareArchitectureIcon from "../Assets/ServicesPage/softwareArchIcon.png";
import scopePrioritiesIcon from "../Assets/ServicesPage/scopePrioritiesIcon.svg";
import MVPIcon from "../Assets/ServicesPage/MVPIcon.svg";
import testingIcon from "../Assets/ServicesPage/testingIcon.svg";
import softwareDocIcon from "../Assets/ServicesPage/softwareDocIcon.svg";
import softwareMainIcon from "../Assets/ServicesPage/softwareMainIcon.svg";
import faqImage from "../Assets/ServicesPage/faqImage.webp";
import dropDownIcon from "../Assets/ServicesPage/dropDownIcon.svg";
import dropUpIcon from "../Assets/ServicesPage/dropUpIcon.svg";
import contactUsBgImage from "../Assets/ServicesPage/contactUsBgImage.webp";
import contactUsBgImageMobile from "../Assets/ServicesPage/contactUsBgImageMobile.webp";
import SEO from "./SEO";
import ContactUsForm from "./ContactUsForm";

const ServicesPage = (props) => {
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);
  const [currentFaq, setCurrentFaq] = useState(0);
  const FaqDivs = [
    {
      faqId: 1,
      question: "What IT services does CREXIN Technologies provide?",
      answerDescription:
        "CREXIN Technologies specializes in digital transformation, cloud computing, enterprise application development, custom software solutions, and IT consulting. These services are tailored to meet the unique needs of businesses.",
    },
    {
      faqId: 2,
      question:
        "How does CREXIN Technologies  ensure the security of its solutions?",
      answerDescription:
        "CREXIN Technologies integrates advanced security measures, such as encryption, access controls, and compliance with industry standards, into every solution to safeguard client data and applications.",
    },
    {
      faqId: 3,
      question: "Can CREXIN Technologies  help with cloud migration?",
      answerDescription:
        "Yes, CREXIN Technologies provides comprehensive cloud migration services, ensuring a seamless transition from on-premises to cloud environments with minimal downtime and maximum scalability.",
    },
    {
      faqId: 4,
      question:
        "Does CREXIN Technologies offer mobile app development?",
      answerDescription:
        "Absolutely. CREXIN Technologies develops secure and responsive mobile applications, including native and cross-platform apps, tailored to your business requirements.",
    },
    {
      faqId: 5,
      question: "What industries does CREXIN Technologies cater to?",
      answerDescription:
        "CREXIN Technologies serves a wide range of industries, including healthcare, finance, retail, education, and more, offering customized solutions that meet specific industry needs.",
    },
    {
      faqId: 6,
      question: "Does CREXIN Technologies provide UX/UI design services?",
      answerDescription:
        "Yes, CREXIN Technologies offers custom UX/UI design services focused on creating intuitive, user-friendly interfaces for web and mobile applications.",
    },
    {
      faqId: 7,
      question:
        "How can CREXIN Technologies help businesses with digital marketing?",
      answerDescription:
        "CREXIN Technologies provides tailored digital marketing services, including SEO, content marketing, social media strategies, and data-driven campaigns, to enhance online visibility, drive engagement, and achieve measurable business growth.",
    },
    {
      faqId: 8,
      question: "How does CREXIN Technologies support businesses in digital transformation?",
      answerDescription:
        "CREXIN Technologies helps businesses embrace digital transformation through cloud solutions, enterprise applications, and process optimization, enabling them to stay competitive in the digital age.",
    },
  ];
  const handleExpandFaq = (id) => {
    setCurrentFaq(id);
  };
  const handleCollapseFaq = () => {
    setCurrentFaq(0);
  };

  const technicalProcessGridData = [
    {
      id: 1,
      name: "Project Discovery",
      description:
        "Creation of a trustworthy Project Charter Document, which outlines the following: Scope, Deliverables, Budget, Timeline",
      greyIcon: projectDiscoveryIcon,
    },
    {
      id: 2,
      name: "Software Prototype",
      description:
        "Creating UI/UX, testing business logic and user flows for early gap detection and elimination to minimize the costs of multiple reworkings while development",
      greyIcon: softwarePrototypeIcon,
    },
    {
      id: 3,
      name: "Technology Evaluation",
      description:
        "Researching and selecting the most suitable technologies, third-party services, libraries, tools, and frameworks to be used",
      greyIcon: technologyEvaluationIcon,
    },
    {
      id: 4,
      name: "Software Architecture",
      description:
        "Designing a high-level software structure and organization (components and relations), along with guidelines that address performance, scalability, maintainability, security, etc",
      greyIcon: softwareArchitectureIcon,
    },
    {
      id: 5,
      name: "Scope Priorities",
      description:
        "Updating the Product Roadmap and Project Schedule to ensure alignment with the go-to-market strategy or other Customer goals",
      greyIcon: scopePrioritiesIcon,
    },
    {
      id: 6,
      name: "MVP Development",
      description:
        "Developing a basic version of the software with essential features, including client-server infrastructure, database, back-end, APIs, 3rd party integrations, front-end (all needed applications), etc",
      greyIcon: MVPIcon,
    },
    {
      id: 7,
      name: "Testing and Validation",
      description:
        "Functional and Non-Functional testing of all software components aims to identify and eliminate defects, prepare for production, and ensure ongoing support of user requests",
      greyIcon: testingIcon,
    },
    {
      id: 8,
      name: "Software Documentation",
      description:
        "Updating system descriptions, design decisions, architecture docs, creating technical help guides, and writing user manuals(in compliance with ISO / IEC / IEEE standards, if required) ",
      greyIcon: softwareDocIcon,
    },
    {
      id: 9,
      name: "Software Maintenance",
      description:
        "Post-release maintenance via an ongoing process of changing,modifying, improving, fixing, optimizing, and updating the software to keep up with end user's needs",
      greyIcon: softwareMainIcon,
    },
  ];

  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />

      <div className="services-page-container">
        <div className="services-page-section1-container">
          <div className="services-page-section1-background-image">
            {window.innerWidth <= 432 ? (
              <img
                src={servicePageBackgroundMobileImage}
                alt="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
              />
            ) : (
              <img src={servicePageBackgroundImage} alt="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age." />
            )}
          </div>
          <div className="services-page-background-layer">
            <div className="services-page-section1-description">
              <h2>Drive Business Growth with Innovative Services</h2>
              <p>
                Discover CREXIN’s comprehensive digital solutions, transforming businesses with cutting-edge technology, web adn mobile app development, and secure cloud services. From UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age.
              </p>
            </div>
          </div>
        </div>
        <div className="services-page-section2-container">
          <div className="section2-title-container">
            <h2>
              <span>Services</span> offered by us
            </h2>
          </div>
          <div className="services-page-section2-services-container-grid">
            <div className="services-page-section2-services-grid services-page-section2-services-grid1">
              <h3>Web Applications Development</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Deliver responsive web applications that enhance user engagement, optimize business processes, and support seamless operations to elevate your business performance.
                  </p>

                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/web-application-development");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={WAD} alt="Cutting-edge progressive web app development for seamless user experiences." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid2">
              <h3>Mobile Applications Development</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Mobile app development focuses on creating secure, responsive, and interactive applications designed to enhance user experience and meet the unique needs of smartphone users.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/mobile-application-development");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={MAD} alt="Cutting-edge progressive mobile app development for seamless user experiences." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid3">
              <h3>Website Design & Development </h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Website design and development emphasizes creating interactive, visually appealing, and responsive web applications that deliver seamless user experiences and meet business goals.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/website-design-development");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={WDD} alt="Single-page application development for fast and responsive web solutions." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid4">
              <h3>Digital Marketing</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Digital marketing utilizes tailored strategies including SEO, content marketing, and social media, to promote brands, drive website traffic, and engage targeted audiences effectively.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/digital-marketing-services");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={DMS} alt="Creative strategies for digital marketing services to grow your online presence." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid5">
              <h3>UI/UX Design</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Custom UX/UI design services focus on enhancing user experience by delivering intuitive, visually appealing, and user-friendly interfaces tailored to meet business and user needs.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/ui-ux-design-services");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={UIUX} alt="Custom UX/UI design services to enhance user engagement and satisfaction." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid6">
              <h3>Graphics Design</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Custom graphics design services combine creativity and visual elements to craft compelling visuals that effectively communicate ideas and strengthen brand messaging.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/graphic-design-services");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={GDS} alt="Custom Graphics design services to enhance user engagement and satisfaction." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid7">
              <h3>Microsoft Application Services</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Microsoft Application Services offer scalable tools and platforms management, and custom business solutions to optimize processes and drive efficiency.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/microsoft-application-services");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={MAS} alt="Advanced business intelligence tools for data-driven decision-making." />
                </div>
              </div>
            </div>
            <div className="services-page-section2-services-grid services-page-section2-services-grid8">
              <h3>Software Maintenance & Support</h3>
              <div className="services-page-section2-service-grid-content-div">
                <div className="services-page-section2-service-grid-description">
                  <p>
                  Software maintenance and support services ensure reliable system functionality by optimizing performance, implementing updates, and resolving issues efficiently to meet business needs.
                  </p>
                  <button
                    className="services-page-explore-now-container-button"
                    onClick={() => {
                      navigate("/our-services/software-maintenance-services");
                      props.scrollToTop();
                    }}
                  >
                    <p>Explore it now</p>
                    <img src={arrowIcon} alt="lets chat" />
                  </button>
                </div>
                <div className="services-page-section2-service-grid-image">
                  <img src={WDD} alt="Comprehensive end-to-end IT solutions for businesses of all sizes." />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="services-page-section3-container">
          <div className="section2-title-container">
            <h2>
              Our <span>Technical Process</span>
            </h2>
          </div>
          {window.innerWidth <= 432 ? (
            <div className="services-page-technical-process-grid-container-mob-responsive">
              {technicalProcessGridData.map((gridItem) => (
                <div
                  key={gridItem.id}
                  className="services-page-technical-process-grid-item-mob-responsive"
                // onMouseEnter={() => setHoveredId(gridItem.id)}
                // onMouseLeave={() => setHoveredId(null)}
                >
                  <div className="services-page-technical-process-grid-id">
                    <h2>{gridItem.id}</h2>
                  </div>
                  <div className="services-page-technical-process-grid-content">
                    <img
                      src={gridItem.greyIcon}
                      alt={gridItem.name}
                      className="services-page-technical-process-grid-icon"
                    />
                    <div className="services-page-technical-process-grid-description">
                      <h3>{gridItem.name}</h3>
                      <p>{gridItem.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          <div className="services-page-technical-process-grid-container">
            {technicalProcessGridData.map((gridItem) => (
              <div
                key={gridItem.id}
                className="services-page-technical-process-grid-item"
                onMouseEnter={() => setHoveredId(gridItem.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                {hoveredId !== gridItem.id ? (
                  <>
                    <h2 className="services-page-technical-process-grid-id">
                      0{gridItem.id}
                    </h2>
                    <img
                      src={gridItem.greyIcon}
                      alt={gridItem.name}
                      className="services-page-technical-process-grid-icon"
                    />
                    <h3>{gridItem.name}</h3>
                  </>
                ) : (
                  <>
                    <h3>{gridItem.name}</h3>
                    <p>{gridItem.description}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="services-page-section4-container">
          <div className="section2-title-container">
            <h2>
              <span>FAQ's</span>
            </h2>
          </div>
          <div className="services-page-section4-content">
            <div className="services-page-section4-faqs-div">
              {FaqDivs.map((faq, index) => {
                return (
                  <div
                    key={index}
                    className={`faq-div ${currentFaq === index + 1 ? "active-faq-div" : ""
                      }`}
                  >
                    <div
                      className="question-div"
                      onClick={() => {
                        currentFaq !== index + 1
                          ? handleExpandFaq(faq.faqId)
                          : handleCollapseFaq();
                      }}
                    >
                      <p>{faq.question}</p>
                      {currentFaq !== index + 1 ? (
                        <img src={dropDownIcon} alt="" />
                      ) : (
                        <img
                          src={dropUpIcon}
                          // onClick={() => {
                          //   handleCollapseFaq();
                          // }}
                          alt=">"
                        />
                      )}
                    </div>
                    <div className="answer-div">
                      {currentFaq === faq.faqId && currentFaq === index + 1 ? (
                        <p>{faq.answerDescription}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="services-page-section4-faqs-image">
              <img src={faqImage} alt="Scalable and secure cloud computing solutions for businesses." />
            </div>
          </div>
        </div>
        <div className="services-page-section5-container">
        <div className="services-page-section5-bg-image">
          {
            window.innerWidth <= 430 ? (<img src={contactUsBgImageMobile} alt="bg" />) : (<img src={contactUsBgImage} alt="bg" />)
          }
        </div>
        <div className="services-page-section5-content">
          <div className="section2-title-container">
            <h2>
              <span>Contact</span> us
            </h2>
          </div>
          <div style={{ margin: "30px auto 0px auto" }}>
            <ContactUsForm />
          </div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
};

export default ServicesPage;
