import React from "react";
import "./Homepage.css";
import toggleCloseButton from "../Assets/Homepage/toggleCloseButton.svg";
const TechStackModalComponent = ({ isOpen, onClose, activeGrid, gridData }) => {
  if (!isOpen) return null;
  
  return (
    <div
      style={modalOverlayStyle}
      onClick={() => {
        onClose();
      }}
    >
      <div style={modalContentStyle}>
        {activeGrid && (
          <div className="homepage-section3-techstack-grid-side-content-div">
            <div className="title-div">
              <h2>{gridData.find((grid) => grid.id === activeGrid)?.name}</h2>
              <img src={toggleCloseButton} alt="X" />
            </div>
            <h4>Our Experience</h4>
            <h5>READY FOR YOUR PRODUCT</h5>
            <p>
              {
                gridData.find((grid) => grid.id === activeGrid)?.content
                  .paragraph1
              }
            </p>
            <p>
              {
                gridData.find((grid) => grid.id === activeGrid)?.content
                  .paragraph2
              }
            </p>
            <p>
              {
                gridData.find((grid) => grid.id === activeGrid)?.content
                  .paragraph3
              }
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
const modalOverlayStyle = {
  position: "absolute",
  zIndex: "3",
  top: 1985,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
};

const modalContentStyle = {
  backgroundColor: "#BBBBBB",
  paddingBottom: "0px",
  borderRadius: "12px",
  textAlign: "left",
  maxWidth: "260px",
  width: "100%",
  height: "340px",
};

const closeButtonStyle = {
  margin: "20px auto",
  padding: "10px 20px",
  backgroundColor: "#339999",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};
const modalParaStyle = {
  width: "100%",
  height: "auto",
  textAlign: "center",
  fontSize: "18px",
  margin: "20px auto 20px auto",
};
export default TechStackModalComponent;
