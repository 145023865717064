import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UiUxDesignServices.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import uiuxSection1Image from "../../Assets/ServicesPage/uiuxSection1Image.webp";
import uiuxSection2Image from "../../Assets/ServicesPage/uiuxSection2Image.webp";
import uiuxSection2MobileImage from "../../Assets/ServicesPage/uiuxSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";

const UiUxDesignServices = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
   <React.Fragment>
   <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
     <div className="ui-ux-ser-container">
      <div className="ui-ux-ser-section1-container">
        <div className="ui-ux-ser-section1-content">
          <h3>UI/UX Design Services</h3>
          <p>
            In the digital era, the success of any product or service is largely
            determined by how users interact with it. User Interface (UI) and
            User Experience (UX) design play crucial roles in shaping this
            interaction. Our UI/UX design services focus on creating intuitive,
            efficient, and engaging experiences for users, while ensuring that
            digital products align with business goals.
          </p>
          <p>
            By optimizing user experience, enhancing interactive web design, and providing
            data-driven UX design, we deliver solutions that meet both user
            needs and business objectives effectively.
          </p>
          <button
            className="wad-page-contact-us-container-button-2"
            onClick={() => {
              navigate("/enquiry-with-us");
            }}
          >
            <p>Contact us</p>
            <img src={arrowIcon} alt="lets chat" />
          </button>
        </div>
        <div className="ui-ux-ser-section1-image">
          <img
            src={uiuxSection1Image}
            alt="Custom UX/UI design services to enhance user engagement and satisfaction."
          />
        </div>
      </div>
      <div className="ui-ux-ser-section2-container">
        <div className="ui-ux-ser-section2-image">
          {window.innerWidth <= 430 ? (
            <img
              src={uiuxSection2MobileImage}
              alt="Custom UX/UI design services to enhance user engagement and satisfaction."
            />
          ) : (
            <img
              src={uiuxSection2Image}
              alt="Custom UX/UI design services to enhance user engagement and satisfaction."
            />
          )}
        </div>
        <div className="ui-ux-ser-section2-content">
          <div className="ui-ux-ser-section2-content-left-div">
            <h3>When to consider our UI/UX Design Service</h3>
            <p>
              UI/UX services are vital for developing digital products that are
              not only visually appealing but also user-friendly, accessible,
              and aligned with business objectives.
            </p>
            <p>
              Whether you're launching a new product, redesigning an existing
              one, or aiming to enhance user satisfaction, investing in
              professional UI/UX design services can lead to better user
              experiences, higher engagement, and increased success in the
              digital marketplace. By focusing on intuitive interface design,
              interactive web design, and data-driven UX design, we ensure that
              your product resonates with users while meeting your strategic
              goals.
            </p>
            {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
          </div>
          <div className="ui-ux-ser-section2-content-right-div">
            <div className="ui-ux-ser-section2-content-right-div-grid">
              <h4>Launching a New Product</h4>
              <p>
                When developing a new digital product, whether it’s a website,
                mobile app, or software, UI/UX services are essential to ensure
                that the product meets user needs and stands out in the market.
              </p>
            </div>
            <div className="ui-ux-ser-section2-content-right-div-grid">
              <h4>Ensuring Accessibility</h4>
              <p>
                If your product needs to be accessible to users with
                disabilities, specialized UI/UX services can ensure compliance
                with accessibility standards and create an inclusive experience.
              </p>
            </div>
            <div className="ui-ux-ser-section2-content-right-div-grid">
              <h4>Conversion Rate Optimization (CRO)</h4>
              <p>
                CRO focuses on improving the effectiveness of your website and
                landing pages to increase the percentage of visitors who
                complete a desired action, like filling out a contact form.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ui-ux-ser-section3-container">
        <h3>
          <span>Technologies</span> used by us{" "}
        </h3>
        <p>
          Our developers use a proven combination of the latest and existing
          trusted technologies, with the following technology stack as the
          foundation:
        </p>
        <div className="ui-ux-ser-tecnhology-div">
          {technologiesData.map((category, index) => (
            <div
              key={category.id}
              className={`tech-category ${category.id === activeCategory.id ? "active-tech-category" : ""
                }`}
              onMouseEnter={() => handleMouseEnter(category)}
            //   onMouseLeave={handleMouseLeave}
            >
              <p>{category.name}</p>
            </div>
          ))}
        </div>
        <div className="ui-ux-ser-technology-techskills">
          <div className="skills-list">
            {activeCategory.technicalSkills &&
              activeCategory.technicalSkills.map((skill, index) => (
                <div key={skill.id} className="tech-skill">
                  <p>{skill.techName}</p>
                  {skill.icon && (
                    <img src={skill.icon} alt={`${skill.techName}`} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="ui-ux-ser-section4-container">
        <div className="ui-ux-ser-section4-image">
          {window.innerWidth <= 430 ? (
            <img
              src={wadSection3MobileImage}
              alt="Custom UX/UI design services to enhance user engagement and satisfaction."
            />
          ) : (
            <img
              src={wadSection3Image}
              alt="Custom UX/UI design services to enhance user engagement and satisfaction."
            />
          )}
        </div>
        <div className="ui-ux-ser-section4-content-layer">
          <div className="ui-ux-ser-section4-content">
            <div className="contact-us-form-container">
              <ContactUsForm />
            </div>
            <div className="ui-ux-ser-section4-right-div">
              <h3>Let's Talk About Your Project</h3>
              <p>
                We are eager to discuss and understand your custom software
                development needs. Whether you have a detailed plan or just a
                concept, we're ready to transform it into a practical and
                tailored solution.
              </p>
              <p>
                Our services prioritize simplicity, effectiveness, and
                collaboration. Ready to move forward? Let's talk about your
                project—fill out the form and click "Submit" to get started.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
   </React.Fragment>
  );
};

export default UiUxDesignServices;
