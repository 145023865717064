import React from 'react'
import ContactUsForm from './ContactUsForm';
import contactUsBgImage from "../Assets/ServicesPage/contactUsBgImage.webp";
import contactUsBgImageMobile from "../Assets/ServicesPage/contactUsBgImageMobile.webp";
import SEO from './SEO';
const Enquiry = () => {
  return (
    <React.Fragment>
      <SEO
        title="Enquiry"
        description="Get in touch with CREXIN for tailored digital solutions. Our team is ready to answer your questions and help transform your business. Contact us to start your journey."
      />
      <div className="services-page-section5-container">
        <div className="services-page-section5-bg-image">
          {
            window.innerWidth <= 430 ? (<img src={contactUsBgImageMobile} alt="Custom software solutions designed to streamline workflows and operations." />) : (<img src={contactUsBgImage} alt="Custom software solutions designed to streamline workflows and operations." />)
          }
        </div>
        <div className="services-page-section5-content">
          <div className="section2-title-container">
            <h2>
              <span>Contact</span> us
            </h2>
          </div>
          <div style={{ margin: "30px auto 0px auto" }}>
            <ContactUsForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Enquiry