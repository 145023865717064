import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MicrosoftApplicationServices.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import masSection1Image from "../../Assets/ServicesPage/masSection1Image.webp";
import masSection2Image from "../../Assets/ServicesPage/masSection2Image.webp";
import masSection2MobileImage from "../../Assets/ServicesPage/masSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";

const MicrosoftApplicationService = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="ms-app-ser-container">
        <div className="ms-app-ser-section1-container">
          <div className="ms-app-ser-section1-content">
            <h3>Microsoft Application Services</h3>
            <p>
              Microsoft Application Services empower businesses to maximize the
              potential of Microsoft’s suite of applications and technologies.
              These services are designed to help organizations optimize
              Microsoft products, streamline business processes, enhance
              productivity, and accelerate digital transformation.
            </p>
            <p>
              By leveraging cloud computing solutions and enterprise application
              development, businesses can integrate Microsoft tools seamlessly
              into their operations, driving greater efficiency and innovation
              across all levels of the organization.
            </p>
            <button
              className="wad-page-contact-us-container-button-2"
              onClick={() => {
                navigate("/enquiry-with-us");
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="ms-app-ser-section1-image">
            <img
              src={masSection1Image}
              alt="Innovative digital transformation services to modernize your business operations."
            />
          </div>
        </div>
        <div className="ms-app-ser-section2-container">
          <div className="ms-app-ser-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={masSection2MobileImage}
                alt="Innovative digital transformation services to modernize your business operations."
              />
            ) : (
              <img
                src={masSection2Image}
                alt="Innovative digital transformation services to modernize your business operations."
              />
            )}
          </div>
          <div className="ms-app-ser-section2-content">
            <div className="ms-app-ser-section2-content-left-div">
              <h3>When to consider our Micorsoft Application Service</h3>
              <p>
                Microsoft Application Services enable businesses to fully
                utilize Microsoft’s suite of products and cloud solutions,
                unlocking their potential for enhanced productivity and
                streamlined operations.
              </p>
              <p>
                Whether you’re looking to implement Microsoft 365, customize
                Dynamics 365, or develop custom applications, our services are
                designed to help you efficiently achieve your business
                objectives. By partnering with us, you ensure that your
                Microsoft applications are fully optimized, secure, and aligned
                with your strategic goals, driving seamless digital
                transformation and business growth.
              </p>
              {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
            </div>
            <div className="ms-app-ser-section2-content-right-div">
              <div className="ms-app-ser-section2-content-right-div-grid">
                <h4>Optimizing Operations</h4>
                <p>
                  When you want to streamline business processes, improve
                  collaboration, and enhance productivity, Microsoft Application
                  Services can help you fully utilize Microsoft’s capabilities.
                </p>
              </div>
              <div className="ms-app-ser-section2-content-right-div-grid">
                <h4>Enhancing Security and Compliance</h4>
                <p>
                  If you need to strengthen your IT security or ensure
                  compliance with industry regulations, Microsoft’s
                  enterprise-grade security features and our services can
                  provide peace of mind.
                </p>
              </div>
              <div className="ms-app-ser-section2-content-right-div-grid">
                <h4>Custom Solutions</h4>
                <p>
                  When off-the-shelf solutions don’t fit your specific needs,
                  our custom application development on Microsoft platforms can
                  provide tailored solutions that align with your business
                  goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-app-ser-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="ms-app-ser-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${
                  category.id === activeCategory.id
                    ? "active-tech-category"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(category)}
                //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="ms-app-ser-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="ms-app-ser-section4-container">
          <div className="ms-app-ser-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Innovative digital transformation services to modernize your business operations."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Innovative digital transformation services to modernize your business operations."
              />
            )}
          </div>
          <div className="ms-app-ser-section4-content-layer">
            <div className="ms-app-ser-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="ms-app-ser-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MicrosoftApplicationService;
