import React from 'react'

const SuccessModalComponent = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div style={modalOverlayStyle}>
        <div style={modalContentStyle}>
          <h2>Submission Successful!</h2>
          <p style={modalParaStyle}>Your form has been successfully submitted.</p>
          <button onClick={onClose} style={closeButtonStyle}>Close</button>
        </div>
      </div>
    );
  
}
const modalOverlayStyle = {
    position: "absolute",
    zIndex:"3",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width:"100%",
    height:"auto",
  };
  
  const modalContentStyle = {
    backgroundColor: "white",
    paddingBottom: "0px",
    borderRadius: "8px",
    textAlign: "center",
    maxWidth: "400px",
    width: "100%",
    height:"200px"
  };
  
  const closeButtonStyle = {
    margin: "20px auto",
    padding: "10px 20px",
    backgroundColor: "#339999",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const modalParaStyle ={
    width : "100%",
    height : "auto",
    textAlign:"center",
    fontSize: "18px",
    margin:"20px auto 20px auto"
  }
export default SuccessModalComponent