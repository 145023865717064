import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DigitalMarketingServices.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import dmsSection1Image from "../../Assets/ServicesPage/dmsSection1Image.webp";
import dmsSection2Image from "../../Assets/ServicesPage/dmsSection2Image.webp";
import dmsSection2MobileImage from "../../Assets/ServicesPage/dmsSection2MobileImage.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";

const DigitalMarketingSerivces = () => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };

  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="digi-mark-ser-container">
        <div className="digi-mark-ser-section1-container">
          <div className="digi-mark-ser-section1-content">
            <h3>Digital Marketing Services</h3>
            <p>
              In today’s digital age, businesses must harness the power of
              online platforms to effectively connect with and engage their
              target audience. Digital marketing services offer the strategies,
              tools, and expertise necessary to promote products, services, and
              brands across a wide range of online channels.
            </p>
            <p>
              By leveraging techniques such as SEO, business process
              optimization, and data-driven UX design, companies can enhance
              their digital presence and drive meaningful interactions with
              potential customers. These services are essential for boosting
              visibility, improving user experience, and ultimately driving
              business growth.
            </p>
            <button
              className="wad-page-contact-us-container-button-2"
              onClick={() => {
                navigate("/enquiry-with-us");
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="digi-mark-ser-section1-image">
            <img
              src={dmsSection1Image}
              alt="Creative strategies for digital marketing services to grow your online presence."
            />
          </div>
        </div>
        <div className="digi-mark-ser-section2-container">
          <div className="digi-mark-ser-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={dmsSection2MobileImage}
                alt="Creative strategies for digital marketing services to grow your online presence."
              />
            ) : (
              <img
                src={dmsSection2Image}
                alt="Creative strategies for digital marketing services to grow your online presence."
              />
            )}
          </div>
          <div className="digi-mark-ser-section2-content">
            <div className="digi-mark-ser-section2-content-left-div">
              <h3>When to consider our Digital Marketing Service</h3>
              <p>
                Digital marketing is essential for business success in today’s
                online-driven world. Knowing when to invest in professional
                digital marketing services can significantly impact the
                achievement of your business objectives.
              </p>
              <p>
                Whether you're launching a new product, improving your online
                presence, or aiming to boost sales, our digital marketing
                services are tailored to address your specific needs. By
                partnering with us, you'll benefit from expert strategies,
                cutting-edge tools, and a team dedicated to helping your
                business thrive in the digital landscape. Through SEO,
                data-driven UX design, and business process optimization, we
                ensure your brand reaches and engages the right audience
                effectively.
              </p>
              {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
            </div>
            <div className="digi-mark-ser-section2-content-right-div">
              <div className="digi-mark-ser-section2-content-right-div-grid">
                <h4>Search Engine Optimization (SEO)</h4>
                <p>
                  SEO is the foundation of any successful digital marketing
                  strategy. It involves optimizing a website’s content,
                  structure, and technical aspects to rank higher in search
                  engine results pages (SERPs).
                </p>
              </div>
              <div className="digi-mark-ser-section2-content-right-div-grid">
                <h4>Social Media Marketing</h4>
                <p>
                  Social media marketing leverages platforms like Facebook,
                  Instagram, Twitter, LinkedIn, to promote your brand and engage
                  with your audience to build brand loyalty and drive
                  conversions.
                </p>
              </div>
              <div className="digi-mark-ser-section2-content-right-div-grid">
                <h4>Conversion Rate Optimization (CRO)</h4>
                <p>
                  CRO focuses on improving the effectiveness of your website and
                  landing pages to increase the percentage of visitors who
                  complete a desired action, like filling out a contact form.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="digi-mark-ser-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="digi-mark-ser-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${
                  category.id === activeCategory.id
                    ? "active-tech-category"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(category)}
                //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="digi-mark-ser-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="digi-mark-ser-section4-container">
          <div className="digi-mark-ser-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Creative strategies for digital marketing services to grow your online presence."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Creative strategies for digital marketing services to grow your online presence."
              />
            )}
          </div>
          <div className="digi-mark-ser-section4-content-layer">
            <div className="digi-mark-ser-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="digi-mark-ser-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DigitalMarketingSerivces;
