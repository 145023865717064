import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blogs.css";
import mobileFirstDesign from "../Assets/Blogs/websiteFirstDesign.webp";
import PocPrototypeMvp from "../Assets/Blogs/PocPrototypeMvp.webp";
import SDLCOverview from "../Assets/Blogs/SDLCOverview.webp";
import ControlSoftwareDevelopmentBudget from "../Assets/Blogs/ControlSoftwareDevelopmentBudget.webp";
import CrossPlatformVsNativeApp from "../Assets/Blogs/CrossPlatformVsNativeApp.webp";
import TechStackForWebDevelopment from "../Assets/Blogs/TechStackForWebDevelopment.webp";
import StaticVsDynamicWebDevelopment from "../Assets/Blogs/StaticVsDynamicWebDevelopment.webp";
import SEO from "./SEO";

const Blogs = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
    <SEO
        title="Blogs"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="blog-main-container">
        <div className="blog-section1-container">
          <div className="blog-section1-content">
            <h1
              className="blog-title"
              onClick={() => {
                navigate(
                  "/blogs/importance-of-mobile-first-design-in-modern-projects"
                );
                props.scrollToTop();
              }}
            >
              The Importance of Mobile-First Design in Modern Projects
            </h1>
            <div className="blog-section1-content-description">
              <div className="blog-section1-content-desc-text">
                <p>
                  In today’s fast-paced digital world, mobile-first design is no
                  longer a trend but a necessity. With mobile devices accounting
                  for over 58% of global web traffic in 2024, businesses must
                  adapt to ensure their projects resonate with users across all
                  devices.
                </p>
                <div
                  className="read-more-text"
                  onClick={() => {
                    navigate(
                      "/blogs/importance-of-mobile-first-design-in-modern-projects"
                    );
                    props.scrollToTop();
                  }}
                >
                  <p>Read more...</p>
                  {/* <img src={readMoreIcon} alt="Read more..." /> */}
                </div>
              </div>

              <div className="blog-section1-content-image">
                <img
                  src={mobileFirstDesign}
                  alt="Mobile First Design"
                  onClick={() => {
                    navigate(
                      "/blogs/importance-of-mobile-first-design-in-modern-projects"
                    );
                    props.scrollToTop();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="blog-section1-grid-container">
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate("/blogs/software-development-life-cycle-overview");
                props.scrollToTop();
              }}
            >
              <img src={SDLCOverview} alt="software Development Life Cycle" />
              <h3 className="grid-heading">
                Software Development Life Cycle (SDLC) Overview
              </h3>
              <p>
                The Software Development Life Cycle (SDLC) provides a clear,
                systematic process that guides development teams from project
                initiation to completion...
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate("/blogs/software-development-life-cycle-overview");
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate("/blogs/poc-prototype-mvp-choosing-the-right-path");
                props.scrollToTop();
              }}
            >
              <img src={PocPrototypeMvp} alt="PocPrototypeMvp" />
              <h3 className="grid-heading">
                PoC, Prototype, or MVP: Choosing the Right Path
              </h3>
              <p>
                Knowing where to start is crucial. Should you build a Proof of
                Concept (PoC), a Prototype, or jump straight to a Minimum Viable
                Product (MVP)
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate("/blogs/poc-prototype-mvp-choosing-the-right-path");
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
                {/* <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="Read more..."
                /> */}
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blogs/how-to-control-your-software-development-budget"
                );
                props.scrollToTop();
              }}
            >
              <img
                src={ControlSoftwareDevelopmentBudget}
                alt="Control Software Development Budget"
              />
              <h3 className="grid-heading">
                How to Control Your Software Development Budget: Revision Costs
              </h3>
              <p>
                Managing a software development budget is a critical aspect of
                project success. One of the main culprits behind budget
                overruns...
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate(
                    "/blogs/how-to-control-your-software-development-budget"
                  );
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
                {/* <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="Read more..."
                /> */}
              </div>
            </div>
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate(
                  "/blogs/static-vs-dynamic-web-app-development-which-is-right-for-your-project"
                );
                props.scrollToTop();
              }}
            >
              <img
                src={StaticVsDynamicWebDevelopment}
                alt="Static vs. Dynamic Web App Development"
              />
              <h3 className="grid-heading">
                Static vs. Dynamic Web App Development: Which is Right for Your
                Project?
              </h3>
              <p>
                Understanding the difference between static and dynamic web apps
                can help you make an informed decision tailored to your ....
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate(
                    "/blogs/static-vs-dynamic-web-app-development-which-is-right-for-your-project"
                  );
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate(
                  "/blogs/how-to-choose-the-right-tech-stack-for-your-website-development"
                );
                props.scrollToTop();
              }}
            >
              <img
                src={TechStackForWebDevelopment}
                alt="TechStackForWebDevelopment"
              />
              <h3 className="grid-heading">
                How to Choose the Right Tech Stack for Your Website Development
              </h3>
              <p>
                Selecting the right technology stack is one of the most crucial
                decisions you'll make when developing a website. The tech
                stack...
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate(
                    "/blogs/how-to-choose-the-right-tech-stack-for-your-website-development"
                  );
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
                {/* <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="Read more..."
                /> */}
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blogs/cross-platform-vs-native-app-development-which-should-you-choose"
                );
                props.scrollToTop();
              }}
            >
              <img
                src={CrossPlatformVsNativeApp}
                alt="Cross-Platform vs. Native App Development"
              />
              <h3 className="grid-heading">
                Cross-Platform vs. Native App Development: Which Should You
                Choose?
              </h3>
              <p>
                One of the critical decisions developers and businesses face is
                choosing between cross-platform and native app development...
              </p>
              <div
                className="read-more-text-grid"
                onClick={() => {
                  navigate(
                    "/blogs/cross-platform-vs-native-app-development-which-should-you-choose"
                  );
                  props.scrollToTop();
                }}
              >
                <p>Read more...</p>
                {/* <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="Read more..."
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Blogs;
