import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./WebsiteDesignDev.css";
import arrowIcon from "../../Assets/Homepage/arrowIcon.svg";
import websiteDesignDevelopment from "../../Assets/ServicesPage/wddSection1Image.webp";
import websiteDesignDevelopmentBackground from "../../Assets/ServicesPage/wddSection2Image.webp";
import htmlLogoIcon from "../../Assets/ServicesPage/htmlLogoIcon.svg";
import cssLogoIcon from "../../Assets/ServicesPage/cssLogoIcon.svg";
import javascriptLogoIcon from "../../Assets/ServicesPage/javascriptLogoIcon.svg";
import reactLogoIcon from "../../Assets/ServicesPage/reactLogoIcon.svg";
import nodeJsLogoIcon from "../../Assets/ServicesPage/nodeJsLogoIcon.svg";
import djangoLogoIcon from "../../Assets/ServicesPage/djangoLogoIcon.svg";
import mongodbLogoIcon from "../../Assets/ServicesPage/mongodbLogoIcon.svg";
import mysqlLogoIcon from "../../Assets/ServicesPage/mysqlLogoIcon.svg";
import awsLogoIcon from "../../Assets/ServicesPage/awsLogoIcon.svg";
import azureLogoIcon from "../../Assets/ServicesPage/azureLogoIcon.svg";
import kotlinLogoIcon from "../../Assets/ServicesPage/kotlinLogoIcon.svg";
import figmaLogoIcon from "../../Assets/ServicesPage/figmaLogoIcon.svg";
import adobeLogoIcon from "../../Assets/ServicesPage/adobeLogoIcon.svg";
import sharepointLogoIcon from "../../Assets/ServicesPage/sharepointLogoIcon.svg";
import websiteDesignDevelopmentMobileBackground from "../../Assets/ServicesPage/wddSection2MobileImage.webp";
import ContactUsForm from "../ContactUsForm";
import wadSection3Image from "../../Assets/ServicesPage/wadSection3Image.webp";
import wadSection3MobileImage from "../../Assets/ServicesPage/wadSection3MobileImage.webp";
import SEO from "../SEO";

const WebsiteDesignDev = (props) => {
  const navigate = useNavigate();
  const technologiesData = [
    {
      id: 1,
      name: "Frontend",
      technicalSkills: [
        {
          id: 1,
          techName: "HTML",
          icon: htmlLogoIcon,
        },
        {
          id: 2,
          techName: "CSS",
          icon: cssLogoIcon,
        },
        {
          id: 3,
          techName: "JAVASCRIPT",
          icon: javascriptLogoIcon,
        },
        {
          id: 4,
          techName: "REACTJS",
          icon: reactLogoIcon,
        },
      ],
    },
    {
      id: 2,
      name: "Backend",
      technicalSkills: [
        {
          id: 1,
          techName: "NODEJS",
          icon: nodeJsLogoIcon,
        },
        {
          id: 2,
          techName: "DJANGO",
          icon: djangoLogoIcon,
        },
      ],
    },
    {
      id: 3,
      name: "Database",
      technicalSkills: [
        {
          id: 1,
          techName: "MONGODB",
          icon: mongodbLogoIcon,
        },
        {
          id: 2,
          techName: "MYSQL",
          icon: mysqlLogoIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Platform",
      technicalSkills: [
        {
          id: 1,
          techName: "AWS",
          icon: awsLogoIcon,
        },
        {
          id: 2,
          techName: "AZURE",
          icon: azureLogoIcon,
        },
      ],
    },
    {
      id: 5,
      name: "Other",
      technicalSkills: [
        {
          id: 1,
          techName: "KOTLIN",
          icon: kotlinLogoIcon,
        },
        {
          id: 2,
          techName: "FIGMA",
          icon: figmaLogoIcon,
        },
        {
          id: 3,
          techName: "ADOBE XD",
          icon: adobeLogoIcon,
        },
        {
          id: 4,
          techName: "SHAREPOINT",
          icon: sharepointLogoIcon,
        },
      ],
    },
  ];
  const [activeCategory, setActiveCategory] = useState(technologiesData[0]); // Track the active category

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };
  return (
    <React.Fragment>
      <SEO
        title="Services"
        description="From custom UX/UI design to enterprise applications, CREXIN empowers your business for success in the digital age."
      />
      <div className="web-des-dev-container">
        <div className="web-des-dev-section1-container">
          <div className="web-des-dev-section1-content">
            <h3>Website Design & Development</h3>
            <p>
              Website design and development focuses on crafting visually
              appealing, user-friendly, and high-performing websites that
              effectively convey a brand's message and cater to user needs. This
              process involves creating interactive web designs, developing
              responsive web applications.
            </p>
            <p>
              And implementing accessible design solutions to enhance user
              engagement, drive traffic, and achieve critical business goals. By
              integrating data-driven UX design and cross-platform UI design, we
              ensure a seamless and impactful digital presence for your brand.
            </p>
            <button
              className="wad-page-contact-us-container-button-2"
              onClick={() => {
                navigate("/enquiry-with-us");
                props.scrollToTop();
              }}
            >
              <p>Contact us</p>
              <img src={arrowIcon} alt="lets chat" />
            </button>
          </div>
          <div className="web-des-dev-section1-image">
            <img
              src={websiteDesignDevelopment}
              alt="Single-page application development for fast and responsive web solutions."
            />
          </div>
        </div>
        <div className="web-des-dev-section2-container">
          <div className="web-des-dev-section2-image">
            {window.innerWidth <= 430 ? (
              <img
                src={websiteDesignDevelopmentMobileBackground}
                alt="Single-page application development for fast and responsive web solutions."
              />
            ) : (
              <img
                src={websiteDesignDevelopmentBackground}
                alt="Single-page application development for fast and responsive web solutions."
              />
            )}
          </div>
          <div className="web-des-dev-section2-content">
            <div className="web-des-dev-section2-content-left-div">
              <h3>When to consider our Website Design & Development Service</h3>
              <p>
                We offer comprehensive website design and development services
                to businesses of all sizes, delivering high-quality solutions
                quickly while focusing on user engagement and responsive design.
              </p>
              <p>
                Investing in website design and development services is crucial
                for any organization aiming to succeed in the digital world. By
                combining visually appealing designs with robust development
                practices, we create websites that not only attract and engage
                visitors but also drive business success through data-driven UX
                design, interactive web design, and seamless cross-platform UI
                design. These services are essential for building a strong
                digital presence that enhances user interaction and supports
                long-term growth.
              </p>
              {/* <button className="wad-page-section2-container-button">
              <p>Lets design & build your Website</p>
              <img src={arrowIcon} alt="lets chat" />
            </button> */}
            </div>
            <div className="web-des-dev-section2-content-right-div">
              <div className="web-des-dev-section2-content-right-div-grid">
                <h4>Designing a New Website</h4>
                <p>
                  When launching a new website, our designing solutions can
                  bring your vision to life with a unique and personalized
                  approach, ensuring your launch is both impactful and
                  memorable.
                </p>
              </div>
              <div className="web-des-dev-section2-content-right-div-grid">
                <h4>Improve User Engagement</h4>
                <p>
                  Good design and UX encourage visitors to stay longer, explore
                  more pages, and ultimately convert into customers or leads.
                </p>
              </div>
              <div className="web-des-dev-section2-content-right-div-grid">
                <h4>Support Business Growth</h4>
                <p>
                  A scalable and flexible website can grow with the business,
                  accommodating new products, services, or features as needed.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="web-des-dev-section3-container">
          <h3>
            <span>Technologies</span> used by us{" "}
          </h3>
          <p>
            Our developers use a proven combination of the latest and existing
            trusted technologies, with the following technology stack as the
            foundation:
          </p>
          <div className="web-des-dev-tecnhology-div">
            {technologiesData.map((category, index) => (
              <div
                key={category.id}
                className={`tech-category ${
                  category.id === activeCategory.id
                    ? "active-tech-category"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(category)}
                //   onMouseLeave={handleMouseLeave}
              >
                <p>{category.name}</p>
              </div>
            ))}
          </div>
          <div className="web-des-dev-technology-techskills">
            <div className="skills-list">
              {activeCategory.technicalSkills &&
                activeCategory.technicalSkills.map((skill, index) => (
                  <div key={skill.id} className="tech-skill">
                    <p>{skill.techName}</p>
                    {skill.icon && (
                      <img src={skill.icon} alt={`${skill.techName}`} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="web-des-dev-section4-container">
          <div className="web-des-dev-section4-image">
            {window.innerWidth <= 430 ? (
              <img
                src={wadSection3MobileImage}
                alt="Single-page application development for fast and responsive web solutions."
              />
            ) : (
              <img
                src={wadSection3Image}
                alt="Single-page application development for fast and responsive web solutions."
              />
            )}
          </div>
          <div className="web-des-dev-section4-content-layer">
            <div className="web-des-dev-section4-content">
              <div className="contact-us-form-container">
                <ContactUsForm />
              </div>
              <div className="web-des-dev-section4-right-div">
                <h3>Let's Talk About Your Project</h3>
                <p>
                  We are eager to discuss and understand your custom software
                  development needs. Whether you have a detailed plan or just a
                  concept, we're ready to transform it into a practical and
                  tailored solution.
                </p>
                <p>
                  Our services prioritize simplicity, effectiveness, and
                  collaboration. Ready to move forward? Let's talk about your
                  project—fill out the form and click "Submit" to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebsiteDesignDev;
