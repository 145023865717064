import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import "./ContactUsForm.css";
import errorIcon from "../Assets/ServicesPage/errorIcon.svg";
import SuccessModalComponent from "./SuccessModalComponent";
import environment from "../environment";
// import whatsAppIcon from "../Assets/ServicesPage/whatsAppIcon.svg";
const ContactUsForm = () => {
  // const [countryCodesList, setCountryCodesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  // const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [geoLocation, setGeoLocation] = useState({ city: "", state: "" });
  // const getCountryCodes = async () => {
  //   let res = await axios.get("https://restcountries.com/v3.1/all");
  //   let resData = res.data;

  //   if (Array.isArray(resData)) {
  //     const filteredData = resData
  //       .map((country) => {
  //         const root = country.idd ? country.idd.root : "";
  //         const suffix =
  //           country.idd && country.idd.suffixes ? country.idd.suffixes[0] : "";

  //         return {
  //           countryCode: country.cca2, // Country's 2-letter code
  //           dialCode: root + suffix, // Full dialing code (root + first suffix)
  //           name: country.name.common, // Country name for display
  //         };
  //       })
  //       .filter((country) => country.dialCode); // Keep only those with a dialing code

  //     setCountryCodesList(filteredData);
  //   }
  // };

  const [formErrors, setFormErrors] = useState({
    fullname: "",
    email: "",
    message: "",
    phone: "",
    company: "",
    website: "",
  });
  const [contactUsRegister, updateContactUsRegister] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      fullname: "",
      email: "",
      phone: "",
      website: "",
      company: "",
      message: "",
    }
  );
  // const handleTextInput = (e) => {
  //     let text = e.target.value;
  //     updateContactUsRegister({
  //         message: text,
  //     });
  // };
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Fullname validation
    let fullnameRegex = /^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*$/;
    if (!contactUsRegister.fullname) {
      errors.fullname = "Required field";
      isValid = false;
    } else if (
      !fullnameRegex.test(contactUsRegister.fullname) ||
      contactUsRegister.fullname.length < 3
    ) {
      errors.fullname = "Please enter a valid fullname (min. 3 characters)";
      isValid = false;
    }

    // Email validation
    if (!contactUsRegister.email) {
      errors.email = "Required field";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(contactUsRegister.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }

    // Message validation
    if (!contactUsRegister.message) {
      errors.message = "Required field";
      isValid = false;
    } else if (contactUsRegister.message.length < 20) {
      errors.message = "Message must be at least 20 characters long";
      isValid = false;
    }

    // Phone validation
    let phoneRegex = /^\d{10}$/; // Example regex for 10-digit phone numbers
    if (!contactUsRegister.phone) {
      errors.phone = "Required field";
      isValid = false;
    } else if (!phoneRegex.test(contactUsRegister.phone)) {
      errors.phone = "Please enter a valid 10-digit phone number";
      isValid = false;
    }

    // Company validation
    if (!contactUsRegister.company) {
      errors.company = "Required field";
      isValid = false;
    } else if (contactUsRegister.company.length < 2) {
      errors.company = "Company name must be at least 2 characters long";
      isValid = false;
    }

    // Website validation
    let websiteRegex = /^(https?:\/\/)?([\w-]+)+[\w-]+(\.[\w-]+)+([/?].*)?$/; // General URL regex
    if (
      contactUsRegister.website &&
      !websiteRegex.test(contactUsRegister.website)
    ) {
      errors.website = "Please enter a valid website URL";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };
  const getGeoLocation = async () => {
    try {
      const response = await axios.get("https://api.ipgeolocation.io/ipgeo", {
        params: { apiKey: "c599697c9c2c439c82a5a12dbc5835c8" },
      });
      const { city, state_prov: state } = response.data;
      setGeoLocation({ city, state });
    } catch (error) {
      console.error("Error fetching geo-location:", error);
    }
  };
  // Handle user input
  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value); // Update the input value
  // };

  // Handle selection from dropdown
  // const handleSelect = (e) => {
  //   setInputValue(e.target.value); // Update the input value based on selection
  // };
  // const handleWhatsAppNumberSubmit = async (e, inputValue, whatsAppNumber) => {
  //   e.preventDefault();
  //   let whatsAppNumberSubmitUrl = environment.baseUrl + "/api/user/enquiry";

  //   if (whatsAppNumber && inputValue) {
  //     let fullWhatsAppNumber = `${inputValue}${whatsAppNumber}`;

  //     // Trim the '+' sign if present at the start
  //     fullWhatsAppNumber = fullWhatsAppNumber.replace(/^\+/, "");
  //     let postData = {
  //       whatsappNumber: fullWhatsAppNumber ? fullWhatsAppNumber : "",
  //       city: geoLocation.city ? geoLocation.city : "",
  //       state: geoLocation.state ? geoLocation.state : "",
  //       enquiryType: "whatsapp",
  //     };
  //     // console.log(postData);
  //     const res = await axios.post(whatsAppNumberSubmitUrl, postData);
  //     const resData = res.data;
  //     if (resData.status === 200) {
  //       setIsModalOpen(true);
  //     } else {
  //       console.log("Error: " + resData.message);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let submitEnquiryUrl = environment.baseUrl + "/api/user/enquiry";
    let postData = {
      message: contactUsRegister.message ? contactUsRegister.message : "",
      fullName: contactUsRegister.fullname ? contactUsRegister.fullname : "",
      email: contactUsRegister.email ? contactUsRegister.email : "",
      phone: contactUsRegister.phone ? contactUsRegister.phone : "",
      company: contactUsRegister.company ? contactUsRegister.company : "",
      website: contactUsRegister.website ? contactUsRegister.website : "",
      city: geoLocation.city ? geoLocation.city : "",
      state: geoLocation.state ? geoLocation.state : "",
      enquiryType: "form",
    };

    if (validateForm()) {
      let res = await axios.post(submitEnquiryUrl, postData);

      let resData = res.data;
      if (resData.status === 200) {
        // alert(resData.message);
        updateContactUsRegister({
          fullname: "",
          email: "",
          message: "",
          phone: "",
          company: "",
          website: "",
        });
        setFormErrors({
          fullname: "",
          email: "",
          message: "",
          phone: "",
          company: "",
          website: "",
        });
        setIsModalOpen(true);
      }
    } else {
      console.log("Please enter valid details");
    }
  };
  useEffect(() => {
    // getCountryCodes();
    getGeoLocation(); // Fetch country codes when the component loads
  }, []);
  return (
    <div className="services-page-section5-form-div">
      <div className="services-page-section-enquiry-form-div">
        <p>
          Please fill out the request for quote form below and our expert team
          will get back to you shortly.
        </p>
        
        <form
          className="needs-validation contact-us-form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="input-row-enq-form input-row-enq-form-1">
            <div className="input-group enq-form-fullname-group has-validation">
              <input
                type="text"
                placeholder="Full Name *"
                className={`form-control formdata enq-form-formdata-fullname ${
                  formErrors.fullname && "is-invalid"
                }`}
                // id="validationCustomUsername"
                required={true}
                value={
                  contactUsRegister.fullname ? contactUsRegister.fullname : ""
                }
                onFocus={() => {
                  setFormErrors({ ...formErrors, fullname: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    fullname: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.fullname
                    ? "invalid-feedback fullname-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.fullname}</p>
              </div>
            </div>
            <div className="input-group enq-form-email-group has-validation">
              <input
                type="text"
                placeholder="Email Address*"
                className={`form-control formdata enq-form-formdata-email ${
                  formErrors.email && "is-invalid"
                }`}
                // id="validationCustomUsername"
                required={true}
                value={contactUsRegister.email ? contactUsRegister.email : ""}
                onFocus={() => {
                  setFormErrors({ ...formErrors, email: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    email: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.email
                    ? "invalid-feedback email-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.email}</p>
              </div>
            </div>
          </div>
          <div className="input-row-enq-form input-row-enq-form-2">
            <div className="input-group enq-form-phone-group has-validation">
              <input
                type="text"
                placeholder="Phone *"
                className={`form-control formdata enq-form-formdata-phone ${
                  formErrors.phone && "is-invalid"
                }`}
                id="validationCustomUsername"
                required={true}
                value={contactUsRegister.phone ? contactUsRegister.phone : ""}
                onFocus={() => {
                  setFormErrors({ ...formErrors, phone: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    phone: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.phone
                    ? "invalid-feedback phone-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.phone}</p>
              </div>
            </div>
            <div className="input-group enq-form-website-group has-validation">
              <input
                type="text"
                placeholder="Website*"
                className={`form-control formdata enq-form-formdata-website ${
                  formErrors.website && "is-invalid"
                }`}
                id="validationCustomUsername"
                required={true}
                value={
                  contactUsRegister.website ? contactUsRegister.website : ""
                }
                onFocus={() => {
                  setFormErrors({ ...formErrors, website: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    website: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.website
                    ? "invalid-feedback website-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.website}</p>
              </div>
            </div>
          </div>
          <div className="input-row-enq-form input-row-enq-form-3">
            <div className="input-group enq-form-company-group has-validation">
              <input
                type="text"
                placeholder="Company*"
                className={`form-control formdata enq-form-formdata-company ${
                  formErrors.company && "is-invalid"
                }`}
                id="validationCustomUsername"
                required={true}
                value={
                  contactUsRegister.company ? contactUsRegister.company : ""
                }
                onFocus={() => {
                  setFormErrors({ ...formErrors, company: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    company: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.company
                    ? "invalid-feedback company-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.company}</p>
              </div>
            </div>
          </div>
          <div className="input-message-row">
            <div className="input-group enq-form-message-group has-validation">
              <textarea
                type="text"
                placeholder="Enter your message..."
                className={`form-control formdata enq-form-formdata-message ${
                  formErrors.message && "is-invalid"
                }`}
                id="validationCustomUsername"
                required={true}
                value={
                  contactUsRegister.message ? contactUsRegister.message : ""
                }
                onFocus={() => {
                  setFormErrors({ ...formErrors, message: "" });
                }}
                onChange={(e) => {
                  updateContactUsRegister({
                    message: e.target.value,
                  });
                }}
              />
              <div
                className={
                  formErrors.message
                    ? "invalid-feedback message-feedback"
                    : "valid-feedback"
                }
              >
                <img alt="" src={errorIcon} className="error-icon" />
                <p>{formErrors.message}</p>
              </div>
            </div>
          </div>
          <div className="contact-us-submit-button-div">
            <button
              className="services-page-form-submit-button"
              type="submit"
              // onClick={(e)=>{handleSubmit(e)}}
            >
              <p>SUBMIT</p>
            </button>
          </div>
        </form>
      </div>
      <SuccessModalComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default ContactUsForm;
